import store from '@/store/index'
import router from '@/router/index'

const ViewModel = function() {}

ViewModel.prototype = {}

Object.assign(ViewModel, {
  getSettings(view) {
    return Object.assign({
      deleteDatasets: false,
      addDatasets: false,
      defaultCellsEditable: true,
      defaultSurveyTheme: null, // surveyjs themes: null, "defaultV2", "modern"
      duplicateDatasets: false,
      editable: false
    }, view.settings || {})
  },
  
  getInViewDefinedVariables(view) {
    return view.variables.filter(variable => view.variableNames.indexOf(variable.name) != -1)
  },

  routeTo(view, queryParams) {
    queryParams = queryParams || {}

    router.push({
      name: 'analysisRedirect3',
      params: {
        sourceName: store.state.source.name,
        viewName: view.name,
        roleName: store.state.roleName
      },
      query: Object.assign({
        analysisSidebar: store.state.analysisSidebar,
        analysisView: store.state.analysisView
      }, queryParams)
    })
  }
})

export default ViewModel