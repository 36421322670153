<template>
  <div class="chips" v-show="$store.state.filterChips.length > 0">
    <md-button class="md-icon-button" @click="deleteAll">
      <md-tooltip>{{$t('tooltips.DELETE_ALL_FILTER')}}</md-tooltip>
      <md-icon>clear</md-icon>
    </md-button>
    <div v-for="(filterChipGroup, index) in $store.state.filterChipGroups" v-bind:key="index" class="chips-group">
      <div class="chips-group-chips">
        <div class="chips-group-name">{{filterChipGroup.name}}</div>
        <md-chip v-for="(filterChip, index) in filterChipGroup.filterChips" v-bind:key="index" class="md-accent" md-deletable @md-delete="deleteFilterChip(filterChip)"><md-tooltip md-delay="200">{{filterChip.value}}</md-tooltip>{{filterChip.value}}</md-chip>
      </div>
    </div>
  </div>
</template>


<script>
import filters from '@/services/filters'

export default {
  name: 'AnalysisFilters',
  methods: {
    deleteFilterChip(filterChip) {
      let filter = filterChip.filter
      let filterValues = this.$store.state.filterValues
      
      let filterValue = filterValues[filter.name]
      
      if (filter.type === 'categorical') {
        filterValue.splice(filterValue.indexOf(filterChip.value), 1)
      } else {
        filterValues[filter.name] = filters.getEmptyFilterValue(filter)
      }
    },
    deleteAll() {
      this.$store.dispatch('clearFilterValues')
    }
  }
}
</script>


<style scoped lang="scss">
  .chips {
    display: flex;
    position: relative;
    overflow: auto;
    padding: 0;
    min-height: 52px;
    align-items: center;
    z-index: 1000;
    border-top: 1px solid #ddd;
    background: #f5f5f5;

    .chips-group {
      float: left;
      background: #fff;
      margin: 3px 5px 3px 0;
      padding: 5px 5px 5px 10px;
      border-radius: 16px;
    }

    .chips-group-name {
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }

    .chips-group-chips {
      display: flex;
      align-items: center;
    }

    :deep {
      .md-chip {
        height: 26px;
        line-height: 26px;
        font-size: 13px;
        margin: 0 0 0 10px;
        background: #999;
        overflow: hidden; 
        text-overflow: ellipsis;
        max-width: 150px;
      }

      .md-chip:first-child {
        margin: 0;
      }
    }
  }
</style>