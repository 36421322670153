<template>
<div class="filter-container" v-if="ready">
  <div class="heading">{{ $t('texts.FILTER') }}</div>
  <div style="display: flex; padding: 0 10px 0 20px; align-items: center; border-bottom: 1px solid #ddd;">
    <div style="flex: 1 0 auto; margin: 0 16px 0 0;"> 
      <md-field style="margin: 5px 0 10px;">
        <label>{{$t('labels.search')}}</label>
        <md-input v-model="search"></md-input>
        <md-icon>search</md-icon>
      </md-field>
    </div>    
    <md-button @click="changeSort()" class="md-icon-button" style="margin: 0; position: relative; top: 6px">
      <md-icon v-if="sort === 'asc'" style="color: #888;">arrow_downward</md-icon>
      <md-icon v-else-if="sort === 'desc'" style="color: #888;">arrow_upward</md-icon>
      <md-icon v-else style="color: #888;">sort_by_alpha</md-icon>
    </md-button>
  </div>
  <md-content class="content md-scrollbar" style="overflow-x: hidden; overflow-y: auto; padding: 0;">
    <md-list>
      <md-list-item v-for="(item, index) in filters" v-bind:key="index" @click="showDialogFor(item)" >
        <variable-badges v-bind:variable="item"></variable-badges>
        <div style="flex: 1 0 0; min-width: 0;">
          <div class="md-list-item-text filter-list-item-label"><md-tooltip md-delay="500" v-if="item.description" md-autohide>{{item.description}}</md-tooltip>{{ item.label }}</div>
          <div v-if="!valueUnset(item)" class="md-list-item-text md-body-1 text-smaller primary-color filter-list-item-values">
            <div>{{ getLabel(item) }}</div>
          </div>
          </div>        
        <md-button class="md-icon-button md-list-action">
          <md-icon>edit</md-icon>
        </md-button>
      </md-list-item>
    </md-list>
  </md-content>

  <md-dialog :md-active.sync="showDialog" class="md-elevation-14">
    <md-toolbar md-elevation="0" class="md-title md-primary">{{ filter.label }}</md-toolbar>

    <md-divider />

    <md-dialog-content>
      <div v-if="filter.type === 'boolean'">
        <md-field>
          <md-switch v-model="$store.state.filterValues[filter.name]"></md-switch>
          
        </md-field>
      </div>
      
      <div v-if="filter.type === 'string'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_STRING_PLACEHOLDER')"></md-input>
        </md-field>
      </div>

     
      <div v-if="filter.type === 'verbatim_n'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_STRING_PLACEHOLDER')"></md-input>
        </md-field>
        <div>
          <alert-view class="help" :title="$t('labels.note')">
            <ul>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_1_a') + filter.label + $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_1_b') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_2') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_3') }}</li>
            </ul>
          </alert-view>
        </div>
        <div>
          <alert-view class="help" :title="$t('labels.syntax')">
            <ul>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_1') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_2') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_3') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_4') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_5') }}</li>
            </ul>
          </alert-view>
        </div>
      </div>

      <div v-if="filter.type === 'text'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_STRING_PLACEHOLDER')"></md-input>
        </md-field>
        <div>
          <alert-view class="help" :title="$t('labels.note')">
            <ul>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_1_a') + filter.label + $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_1_b') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_2') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_INFO.LIST_ENTRY_3') }}</li>
            </ul>
          </alert-view>
        </div>
        <div>
          <alert-view class="help" :title="$t('labels.syntax')">
            <ul>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_1') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_2') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_3') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_4') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_5') }}</li>
              <li>{{ $t('texts.FILTER_DIALOG_SYNTAX.LIST_ENTRY_6') }}</li>
            </ul>
          </alert-view>
        </div>
      </div>

      <div v-else-if="filter.type === 'array_of_strings'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_ARRAY_OF_STRINGS_PLACEHOLDER')"></md-input>
        </md-field>
        <alert-view class="help" :title="$t('labels.note')">
          <ul>
            <li>{{ $t('texts.FILTER_ARRAY_OF_STRINGS_NOTE_1') }}</li>
            <li>{{ $t('texts.FILTER_ARRAY_OF_STRINGS_NOTE_2') }}</li>
          </ul>
          <div class="md-body-1 mt-2">{{ $t('labels.examples_of_values') }}</div>
          <div class="examples mt-1">
            <span>477</span><span>K12, 477</span>
          </div>
        </alert-view>
      </div>

      <div v-else-if="filter.type === 'number'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_NUMBER_PLACEHOLDER')">
          </md-input>          
        </md-field>
        <alert-view class="help" :title="$t('labels.note')">
          <ul>
            <li>{{ $t('texts.FILTER_NUMBER_NOTE_1') }}</li>
            <li>{{ $t('texts.FILTER_NUMBER_NOTE_2') }}</li>
            <li>{{ $t('texts.FILTER_NUMBER_NOTE_3') }}</li>
            <li>{{ $t('texts.FILTER_NUMBER_NOTE_4') }}</li>
          </ul>
          <div class="md-body-1 mt-2">{{ $t('labels.examples_of_values') }}</div>
          <div class="examples mt-1">
            <span>1.3</span><span>22</span><span>>22</span><span>22-40</span><span>22-40, >60</span>
          </div>
        </alert-view>
      </div>

      <div v-else-if="filter.type === 'date' || filter.type === 'datetime'">
        <md-field>
          <md-input v-model="$store.state.filterValues[filter.name]"
            :placeholder="$t('texts.FILTER_DATE_PLACEHOLDER')"></md-input>
        </md-field>
        <alert-view class="help" :title="$t('labels.note')">
          <ul>
            <li>{{ $t('texts.FILTER_DATE_NOTE_1') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_2') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_3') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_4') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_5') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_6') }}</li>
            <li>{{ $t('texts.FILTER_DATE_NOTE_7') }}</li>
          </ul>
          <div class="md-body-1 mt-2">{{ $t('labels.examples_of_values') }}</div>
          <div class="examples mt-1">
            <span>2018/03/28</span><span>2018/03</span><span>&gt;=today(-2y)</span><span>&gt;2015</span><span>&lt;=2016/05</span><span>2016-thisyear</span><span>2017/03-2018</span><span>2012, 2014, 2016-2018</span>
          </div>
        </alert-view>
      </div>

      <div v-else-if="filter.type === 'categorical'">
        <div class="dialog-search-sort">
          <div>
            <md-field>
              <label>{{$t('labels.search')}}</label>
              <md-input ref="dialogSearchInput" @focus="dialogSearch = dialogSearch || ''" @blur="dialogSearch = dialogSearch || null" size=0 v-model="dialogSearch"></md-input>
              <md-icon>search</md-icon>
            </md-field>
          </div>           
          <md-button @click="changeDialogSort()" class="md-icon-button" style="margin: 0; position: relative;">
            <md-icon v-if="dialogSort === 'asc'" style="color: #888;">arrow_downward</md-icon>
            <md-icon v-else-if="dialogSort === 'desc'" style="color: #888;">arrow_upward</md-icon>
            <md-icon v-else style="color: #888;">sort_by_alpha</md-icon>
          </md-button>              
        </div>
        <div class="treeselect-wrapper">
          <input-treeselect :filter="filter" :sorting="dialogSort" :searchText="dialogSearch" @change="treeselectChange" />
        </div>
      </div>

    </md-dialog-content>

    <md-divider />

    <md-dialog-actions>
      <!-- <md-button v-if="filter.multiple" class="md-default">
        Alle abwählen</md-button> -->
      <md-button v-if="filter.type !== 'categorical'" class="md-default" @click="unset">{{ $t('actions.reset') }}</md-button>
      <md-button class="md-primary md-raised" @click="showDialog = false">OK</md-button>
    </md-dialog-actions>
  </md-dialog>
</div>
</template>

<script>
import filters from '@/services/filters'

export default {
  name: 'AnalysisFilters',
  data: () => ({
    ready: false,
    showDialog: false,
    filter: {},
    filterSorting: null,
    filters: [], // current filter as seen
    search: '',
    sort: null, // sort direction -> null, 'asc', 'desc'
    dialogSort: null, // sort direction in dialog -> null, 'asc', 'desc'
    dialogSearch: ''
  }),
  methods: {
    change (val) {
      const name = this.filter.name
      this.$store.state.filterValues[name] = val
    },
    treeselectChange (valueChanges) {
      _.each(valueChanges, (v, k) => {
        this.$store.state.filterValues[k] = v
      })
    },
    unset () {
      if (!this.filter) return

      this.$store.state.filterValues[this.filter.name] = filters.getEmptyFilterValue(this.filter)
      this.showDialog = false
    },
    updateSearch (searchText) {
      let searchRegex = new RegExp(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig')

      this.filters = this.$store.state.filters.filter(filter => {
        return filter.label.match(searchRegex)
      })
      this.updateSort()
    },
    getFilter () {
      return [{
        description: "Ein TargetYear beginnt im November des Vorjahres und endet im Oktober des aktuellen Jahres (vor 2018: Oktober-September)",
        group: "Zeitraum",
        label: "TargetYear",
        multiple: false,
        name: "fahrzeugdaten.targetyear",
        scale: "ratio",
        type: "verbatim"
      }].concat(this.$store.state.filters)
    },
    updateSort () {
      switch (this.sort) {
        case null:
          let filters = []
          this.$store.state.filters.forEach(filter1 => {
            let index = this.filters.findIndex(filter2 => filter1.label === filter2.label)
            if (index !== -1) {
              filters.push(this.filters[index])
            }
          })
          this.filters = filters
          break
        case 'asc':
          this.filters.sort((a, b) => a.label.localeCompare(b.label))
          break
        case 'desc':
          this.filters.sort((a, b) => b.label.localeCompare(a.label))
      }
    },
    changeSort () {
      switch (this.sort) {
        case null:
          this.sort = 'asc'
          break
        case 'asc':
          this.sort = 'desc'
          break
        case 'desc':
          this.sort = null  
      }
      this.updateSort()
    },
    changeDialogSort () {
      switch (this.dialogSort) {
        case null:
          this.dialogSort = 'asc'
          break
        case 'asc':
          this.dialogSort = 'desc'
          break
        case 'desc':
          this.dialogSort = null  
      }
    },
    showDialogFor (item) {
      this.dialogSort = null
      this.dialogSearch = ''
      this.filter = item
      this.showDialog = true
    },
    getLabel (item) {
      let filterValues = this.$store.state.filterValues
      let val = filterValues[item.name]
      if (item.treeLevels) {
        let labelArr = []
        item.treeLevels.map(variableName => {
          if (filterValues[variableName] && filterValues[variableName].length !== 0) {
            labelArr.push(item.treeLabels[variableName] + ': ' + filterValues[variableName].join(', '))
          }
        })
        return labelArr.join(' | ')
      } else if (_.isArray(val)) {
        return val.join(', ')
      }
      return val
    },
    valueUnset (item) {
      // Checks if a filter value is unset or not
      let filterValues = this.$store.state.filterValues
      if (item.treeLevels) {
        // Nested filter
        // Here the value is unset if no value is present on all tree levels
        let numSelected = 0
        _.each(item.treeLevels, filterVarName => {
          if (filterValues[filterVarName] && filterValues[filterVarName].length > 0)
            numSelected++
        })
        return numSelected === 0
      } else if (_.isArray(filterValues[item.name])) {
        return filterValues[item.name].length === 0
      }
      return !filterValues[item.name]
    }
  },
  created () {
    this.filters = this.$store.state.filters.slice(0)
    this.ready = true
  },
  watch: {
    search (val) {
      this.updateSearch(val)
    },
    '$store.state.filters': function(filters) {
      this.filters = filters.slice(0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-container {
    flex: 1 0 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .filter-list-item-label {
    color: rgba(0, 0, 0, 0.87);
  }

  .filter-list-item-values {
    color: $primary;
  }

  .heading {
    color: $primary;
    flex: 0 0 auto;
    padding: 16px 16px 12px 16px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(221, 221, 221);
    white-space: nowrap;
    text-transform: uppercase;
  }

  .content {
    flex: 1 0 0;
  }

  .footer {
    flex: 0 0 auto;
    border-top: 1px solid rgb(221, 221, 221);
    text-align: center;
  }  
  
  .dialog-search-sort {
    display: flex;
    align-items: center;
    margin: 0 12px;

    > div:first-child {
      flex: 1 0 auto;
      .md-field {
        margin: 0 12px 16px 16px; 
        min-height: 32px;
        width: auto;
      }
    }

    > .md-icon {
      margin: 0 16px 0 0;
    }

    > button {
      margin: 0;
    }
  }

  :deep {
    .md-list {
      padding: 0;
    }

    .md-list-item {
      border-top: 1px solid $border-grey;
      
      .md-icon {
        color: rgba(0, 0, 0, 0.7);
        opacity: .2;
        transition: opacity 300ms; 
      }
    }

    .md-list-item-content {
      min-height: auto;
      padding: 0px 16px 0 4px;
    }

    .md-list-item-text {
      font-size: 14px;
    }

    .md-list-item:first-child {
      border-top: none;
    }

    .md-list-item:hover {
      .md-icon {
        opacity: 1;
      }
    }
  }

  .treeselect-wrapper {
    min-height: 320px;
  }

  .help {
    ul {
      margin: 0;
      li {
        margin: 0;
        padding-left: 8px;
      }
    }
    .examples {
      span {
        display: inline-block;
        margin: 0 8px 4px 0;
        background: #e2e2e2;
        padding: 4px 8px;
      }
    }
  }
</style>