import store from '@/store/index'

export default {
  async open(options) {
    options = Object.assign({
      surveyDefinition: null
    }, options || {})

    let dialogObj

    let data = await new Promise(resolve => {
      dialogObj = {
        options: options,
        resolve: resolve
      }

      let surveyCreatorDialogs = store.state.surveyCreatorDialogs
      surveyCreatorDialogs.push(dialogObj)

      store.dispatch('setSurveyCreatorDialogs', surveyCreatorDialogs)
    })

    await store.dispatch('setSurveyCreatorDialogs', store.state.surveyCreatorDialogs.filter(dialog => dialog !== dialogObj))

    return data
  }
}