<template>
  <div>
    <v-dialog
      content-class="data-entry-dialog"
      v-model="opened"
      fullscreen
      persistent
      width="100%">
    
      <v-card style="display: flex; flex-direction: column;">
        <v-card-title style="flex: 0 0 0;" class="text-h6 white--text primary">
          <span>{{ title }}</span><span v-if="title && surveyCurrentPageTitle">&nbsp;/&nbsp;</span><span>{{surveyCurrentPageTitle}}</span>
          
          <v-spacer></v-spacer>

          <!--<v-menu v-if="locales.length > 1" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="text-capitalize" v-bind="attrs" v-on="on" dark text>
                <v-icon left>mdi-translate</v-icon>
                {{ locale }}
                <v-icon small right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(newLocale, index) in locales" :key="index" @click="locale = newLocale">
                <v-list-item-title>{{ newLocale }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->
        </v-card-title>

        <v-card-text style="padding: 0; display: flex; flex: 1 1 0; min-height: 0;">
          <div v-if="ready" style="display: flex; flex: 1 1 auto;">
            <div v-if="additionalSurveys.length > 0" style="flex: 0 0 0; background: #eee;">
              <v-tooltip v-for="(additionalSurvey, index) in additionalSurveys" v-bind:key="index" bottom open-delay="1000" :offset-overflow="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="openAdditionalSurvey(additionalSurvey)">
                    <v-icon>{{ additionalSurvey.icon || 'mdi-card-text-outline' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ additionalSurvey.label }}</span>
              </v-tooltip>
            </div>
            <div style="flex: 1 1 0; display: flex;">
                <survey :survey="survey" />
            </div>
            <div class="tabs-sidebar" v-if="showTabs">
              <v-card style="display: flex; flex: 1 1 auto; flex-direction: column; width: 100%; box-shadow: none;">
                <v-tabs v-model="activeTab" style="display: flex; flex: 0 0 auto;">
                  <v-tab v-if="showAudioTab">{{ $t('texts.AUDIO') }}</v-tab>
                  <v-tab v-if="showPhoneCallDialerTab">{{ $t('texts.CALL') }}</v-tab>
                </v-tabs>

                <v-card-text style="flex: 1 1 auto; display: flex; padding: 0;">
                  <v-tabs-items v-model="activeTab">
                    <v-tab-item v-if="showAudioTab">
                      <audioplayer :audioData="audioData"></audioplayer>
                    </v-tab-item>
                    <v-tab-item v-if="showPhoneCallDialerTab">
                      <phone-call-dialer :rowData="rowData" />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>

        <v-card-actions style="flex: 0 0 0; border-top: 1px solid rgb(233, 233, 233);">
          <v-btn v-if="!isFirstPage || !isLastPage" :disabled="isFirstPage" color="primary" text @click="survey.prevPage()">{{ $t('actions.back') }}</v-btn>
          <v-btn v-if="showNextPage" :disabled="disableNextPage" color="primary" text @click="survey.nextPage()">{{ $t('actions.continue') }}</v-btn>
          
          <v-spacer></v-spacer>
          
          <template v-if="dialogType == 'main'">
            <template v-if="isCati">
              <v-list-item-action style="margin: 0 20px 0 0;">
                <v-checkbox v-model="nextAddress" label="nächsten Adresse"></v-checkbox>
              </v-list-item-action>

              <v-btn text @click="cancel">{{ $t('actions.stop_interview') }}</v-btn>
              
              <v-btn v-if="isLastPage" color="primary" @click="save">{{ $t('actions.complete_interview') }}</v-btn>
            </template>
            
            <template v-if="!isCati">
              <v-btn v-if="!saveAndNextButton" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
              <v-btn  text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
              <v-btn v-if="saveAndNextButton" color="primary" @click="saveAndNext" :disabled="!hasNext">{{ $t('actions.save_and_next') }}</v-btn>
              <v-btn v-if="saveAndNextButton" color="primary" @click="save">{{ $t('actions.save_and_close') }}</v-btn>
            </template>
          </template>
          
          <template v-if="dialogType != 'main'">
            <v-btn text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
            <v-btn v-if="!saveAndNextButton" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
          </template>     
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as SurveyKnockoutUi from "survey-knockout-ui"
import _ from 'lodash'
import { t } from '@/i18n'

import ViewModel from '@/models/view'
import surveyJsUtil from '@/services/surveyjs/util'
import confirmDialog from '@/services/confirmDialog'
import dateFormat from 'dateformat'
import dataEntry from '@/services/dataEntry'
import error from '@/services/error'
import api from '@/services/api'
import localStorageHelper from '@/services/localStorageHelper'

export default {
  name: 'DataEntry',

  data: () => ({
    activeTab: 'one',
    additionalSurveys: [],
    additionalSurveyDataById: {},
    audioData: null,
    collectData: false,
    data: null,
    dataChanged: false,
    dataInterface: null,
    defaultCellsEditable: null,
    hasNext: false,
    intermediateSave: false,
    isAudioEnabled: false,
    isCati: false,
    isFirstPage: false,
    isLastPage: false,
    isSaveAndNext: false,
    label: null,
    dialogType: null,
    nextAddress: true,
    //locale: null,
    //locales: [],
    onSaveFuncs: [],
    opened: false,
    ready: false,
    resultId: null,
    rowData: null,
    saveAndNextButton: false,
    showPhoneCallDialer: false,
    survey: null,
    surveyCurrentPageTitle: null,
    title: null
  }),

  props: {
    dialogObj: Object
  },

  computed: {
    disableNextPage() {
      return this.isLastPage || surveyJsUtil.getSurveyOption(this.survey, 'hideContinue')
    },
    showNextPage() {
      return !this.isFirstPage || !this.isLastPage
    },
    showTabs() {
      return this.showAudioTab || this.showPhoneCallDialerTab
    },

    showAudioTab() {
      return this.audioData
    },

    showPhoneCallDialerTab() {
      return this.showPhoneCallDialer && this.$store.state.phoneCall
    }
  },

  watch: {
    /*locale(locale) {
      if (this.survey && locale) {
        this.survey.locale = locale
      }
    }*/
  },

  methods: {
    async cancel() {
      let surveyObj = this.surveyObj
      let cancelSurvey = surveyObj && surveyObj.cancelSurvey
      let dataChanged = false

      if (cancelSurvey) {
        let returnObj = await dataEntry.open({
          collectData: false,
          dataInterface: this.dataInterface,
          intermediateSave: this.intermediateSave,
          dialogType: 'cancel',
          rowData: this.rowData,
          returnData: this.returnData,
          showPhoneCallDialer: false,
          saveAndNextButton: false,
          surveyObj: cancelSurvey,
          view: this.view
        })

        if (returnObj.cancel) {
          return
        }

        dataChanged = true
      }



      if (this.isCati ? this.nextAddress : false) {
        await this.openNext()
      } else {
        this.close()
        this.dialogObj.resolve({
          cancel: true,
          dataChanged: dataChanged
        })
      }
    },

    adjustSurvey() {
      this.survey.getAllQuestions().forEach(question => {
        if(question.isDescendantOf("selectbase")) {
          // By default incorrect values (e.g. values not in the choices array of a dropdown) are not kept and will give a value of undefined if the survey is saved
          // Setting keepIncorrectValues on a question to true will change the default behaviour, to keeping an incorrect value until the question value is changed by the user.
          // https://github.com/tema-q/woenenn-vue-client/issues/660
          question.keepIncorrectValues = true
        }
      })
    },

    close() {
      this.opened = false
    },

    checkTimestampInterval() {
      return
      
      this.checkTimeoutTimeout = setTimeout(async () => {
        let data = (await api.call('checkTimestamp', {
          sourceName: this.$store.state.source.name,
          viewName: this.view.name,
          surveyId: this.surveyObj.id,
          resultId: this.resultId,
          params: {
            timeoutKey: localStorageHelper.getTimeoutKey()
          }
        })).data

        if (data.state == 'expired') {
          await confirmDialog.open(
            'expired',
            'expired',
            {
              hideCancel: true
            }
          )
        }

        this.checkTimestampInterval()
      }, 2000)
    },

    createSurvey(data) {
      let $this = this

      let survey = this.survey = new SurveyKnockoutUi.Model(this.surveyDefinition)
      survey._dataEntry = this

      surveyJsUtil.addTextMarkdown(survey)

      /*
      * The widget interface can be accessed from within the widget
      */
      survey._widgetInterface = {
        onSave: {
          add(func) {
            $this.onSaveFuncs.push(func)
          },
          remove(func) {
            $this.onSaveFuncs = $this.onSaveFuncs.filter(onSaveFunc => func !== onSaveFunc)
          }
        }
      }

      survey.onCurrentPageChanging.add(this.onCurrentPageChanging)
      survey.onPropertyChanged.add(this.onPropertyChanged)
      survey.onValueChanged.add(this.onValueChanged)
      
      this.updatePage()
      this.adjustSurvey()
      this.setData(data)
    },

    getSurveyJsDefinition(surveyDefinition) {
      let surveyOptions = {
        showNavigationButtons: "none",
        showCompletedPage: false,
        showTitle: false,
        showPageTitles: false
      }    

      if (surveyDefinition) {
        surveyDefinition = Object.assign({}, surveyOptions, surveyDefinition)
    
        //surveyJsUtil.extendSurveyJsDefinition(surveyDefinition, this.view.variables)
      } else {
        let elements = []
        let pages = [{
          elements: elements
        }]

        this.view.variables.forEach(variable => {
          let variableEditable = variable.editable == null ? this.defaultCellsEditable : variable.editable

          if (variableEditable) {
            let computedElementDefinition = surveyJsUtil.computeElementDefinition(variable)
            computedElementDefinition && elements.push(computedElementDefinition)
          }
        })

        surveyDefinition = Object.assign({}, surveyOptions, {
          pages: pages
        })
      }

      return surveyDefinition
    },

    async openAdditionalSurvey(additionalSurvey) {
      let additionalSurveyData = this.additionalSurveyDataById[additionalSurvey.id]

      let returnObj = await dataEntry.open({
        collectData: !this.isCati,
        data: additionalSurveyData,
        dataInterface: this.dataInterface,
        intermediateSave: this.intermediateSave,
        dialogType: 'additional',
        rowData: this.rowData,
        returnData: this.returnData,
        showPhoneCallDialer: false,
        saveAndNextButton: false,
        surveyObj: additionalSurvey,
        view: this.view
      })

      if (!returnObj.cancel) {
        this.additionalSurveyDataById[additionalSurvey.id] = returnObj.data[additionalSurvey.id]
      }

      this.dataChanged = !!(this.dataChanged || returnObj.dataChanged)
    },

    updatePage() {
      const survey = this.survey

      this.isFirstPage = survey.isFirstPage
      this.isLastPage = survey.isLastPage
      this.title = survey.title
      this.surveyCurrentPageTitle = survey && survey.currentPage ? survey.currentPage.title : ''
    },

    async saveSurvey() {
      this.dataChanged = true
      
      this.dataInterface && await this.dataInterface.setData({
        data: {
          [this.surveyObj.id]: this.getData()
        },
        rowData: this.rowData
      })
    },

    async updateTimestamp() {
      if (
        (!api.lastCallTime || Date.now() > api.lastCallTime + 60000) &&
        this.$store.state.source &&
        this.view &&
        this.surveyObj && 
        this.resultId
      ) {
        // if it's one minute since last call update timestamp
        await api.call('updateTimestamp', {
          sourceName: this.$store.state.source.name,
          viewName: this.view.name,
          surveyId: this.surveyObj.id,
          resultId: this.resultId,
          params: {
            timeoutKey: localStorageHelper.getTimeoutKey()
          }
        })
      }
    },

    async onCurrentPageChanging() {
      this.$store.dispatch('setLoading', true)
      this.updatePage()

      if (this.intermediateSave) {
        await this.saveSurvey()
      } else {
        await this.updateTimestamp()
      }

      this.$store.dispatch('setLoading', false)
    },

    onPropertyChanged() {
      this.updatePage()
    },

    async onValueChanged() {
      await this.updateTimestamp()
    },

    async init(surveyObj) {
      try {
        this.$store.dispatch('setLoading', true)

        let id = this.resultId = this.rowData && this.rowData._id

        if (this.isAudioEnabled && this.rowData && this.rowData._description && this.rowData._description.audio) {
          this.audioData = this.rowData._description.audio
        } else {
          this.audioData = null
        }

        this.surveyObj = surveyObj
        
        this.label = id ? this.$t('headers.edit') : this.$t('headers.insert')
        this.additionalSurveys = surveyObj ? surveyObj.additionalSurveys || [] : []

        let data = this.data || (id ? ((await api.call('getSourceViewSurveyResult', {
          sourceName: this.$store.state.source.name,
          viewName: this.view.name,
          surveyId: surveyObj.id,
          resultId: id,
          params: {
            timeoutKey: localStorageHelper.getTimeoutKey()
          }
        })).data).data : null)

        this.createSurvey(data)

        this.survey.onComplete.add(async survey => {
          let surveyData = this.getData()
          let data = null

          if (this.isCati) {
            data = {
              [surveyObj.id]: surveyData
            }
          } else {
            // collect return data when not in cati modus
            this.returnData[surveyObj.id] = surveyData

            data = this.returnData
          }

          for (let i = 0; i < this.onSaveFuncs.length; ++i) {
            await this.onSaveFuncs[i](surveyData)
          }

          this.dataChanged = true
          
          await this.resolve({
            cancel: false,
            data: data,
            rowData: this.rowData
          })
        })

        if (this.dialogType == 'main') {
          this.checkTimestampInterval()
        }
        
        this.ready = true

        this.$store.dispatch('setLoading', false)
      } catch(e) {
        this.reject(error.new(e, {}))
      }
    },

    async open(options) {
      options = Object.assign({
        collectData: false,
        data: null,
        dataInterface: null,
        intermediateSave: false,
        isCati: false,
        dialogType: 'main',
        returnData: null,
        rowData: null,
        saveAndNextButton: false,
        showPhoneCallDialer: false,
        surveyObj: null,
        view: null
      }, options || {})

      let {
        collectData,
        data,
        dataInterface,
        hasNext,
        intermediateSave,
        isCati,
        dialogType,
        returnData,
        rowData,
        saveAndNextButton,
        showPhoneCallDialer,
        surveyObj,
        view
      } = options

      this.collectData = collectData
      this.data = data
      this.dataInterface = dataInterface
      this.hasNext = !!hasNext
      this.intermediateSave = isCati || intermediateSave
      this.isCati = isCati
      this.dialogType = dialogType
      this.returnData = returnData || {}
      
      this.view = view

      let viewSettings = ViewModel.getSettings(view)

      // set theme
      let theme = viewSettings.defaultSurveyTheme || 'legacy'
      theme = (surveyObj && surveyObj.theme !== undefined) ? surveyObj.theme || 'legacy' : theme

      surveyJsUtil.applyStyle({ theme: theme })

      this.isAudioEnabled = viewSettings && (viewSettings.audio === true) && dialogType == 'main'

      this.defaultCellsEditable = viewSettings.defaultCellsEditable
      
      this.surveyDefinition = this.getSurveyJsDefinition(surveyObj ? surveyObj.surveyDefinition : null)

      this.showPhoneCallDialer = showPhoneCallDialer
      this.saveAndNextButton = saveAndNextButton

      if (rowData) {
        this.rowData = rowData
      } else if (this.dataInterface.next) {
        let returnObj = await this.dataInterface.next()

        if (returnObj) {
          this.rowData = returnObj.data
          this.hasNext = returnObj.hasNext
        } else {
          await confirmDialog.open(
            '',
            t('texts.NO_DATA_FOUND'),
            {
              hideCancel: true
            }
          )

          return
        }
      } else {
        this.hasNext = false
      }

      await this.init(surveyObj)

      this.opened = true
    },

    async openNext() {
      this.$store.dispatch('setLoading', true)

      // reset data
      this.data = null
      this.returnData = {}
      
      let returnObj = await this.dataInterface.next()

      if (returnObj) {
        this.rowData = returnObj.data
        this.hasNext = returnObj.hasNext

        if (!this.rowData) {
          this.close()

          this.dialogObj.resolve({
            dataChanged: this.dataChanged
          })
        } else {
          await this.init(this.surveyObj)
        }

        this.$store.dispatch('setLoading', false)
      } else {
        this.close()

        this.$store.dispatch('setLoading', false)

        await confirmDialog.open(
          '',
          t('texts.NO_DATA_FOUND'),
          {
            hideCancel: true
          }
        )

        this.dialogObj.resolve({
          dataChanged: this.dataChanged
        })
      }
    },

    reject(e) {
      this.close()

      this.dialogObj.reject(e)
    },

    async resolve(obj) {
      obj.dialogType = this.dialogType
      obj.dataChanged = this.dataChanged

      if (!this.collectData && obj && obj.data) {
        if (!obj.cancel && (this.dialogType == 'main' || this.dialogType == 'cancel')) {
          obj.completeSurvey = true
        }

        this.dataInterface && await this.dataInterface.setData(obj)
      }

      if (!obj.cancel && (this.isCati ? this.nextAddress : (this.isSaveAndNext && this.hasNext))) {
        await this.openNext()
      } else {
        this.close()
        this.dialogObj.resolve(obj)
      }

      this.isSaveAndNext = false
    },

    async saveAndNext() {
      this.isSaveAndNext = true

      await this.save()
    },

    async save() {
      let surveyObj = this.surveyObj
      let saveSurvey = surveyObj && surveyObj.saveSurvey

      if (saveSurvey) {
        let returnObj = await dataEntry.open({
          collectData: !this.isCati,
          dataInterface: this.dataInterface,
          intermediateSave: this.intermediateSave,
          dialogType: 'save',
          rowData: this.rowData,
          returnData: this.returnData,
          showPhoneCallDialer: false,
          saveAndNextButton: false,
          surveyObj: saveSurvey,
          view: this.view
        })

        this.dataChanged = !!(this.dataChanged || returnObj.dataChanged)

        if (returnObj.cancel) {
          return
        }
      }

      this.survey.completeLastPage()
    },

    getData() {
      let survey = this.survey
      let data = survey.data

      survey.getAllQuestions().forEach(question => {
        let type = question.getType()
        let inputType = question.inputType
        
        if (
          type == 'text' &&
          inputType == 'datetime-local'
        ) {
          let value = data[question.name]

          
          
          if (value) {
            /**
             * The datetime has to be converted to UTC time
             */
            data[question.name] = dateFormat(new Date(value), "yyyy-mm-dd'T'HH:MM:ss", true)
          }
        }
      })

      return data
    },

    setData(data) {
      if (data) {
        let questions = this.survey.getAllQuestions()

        questions.forEach(question => {
          let value = _.get(data, question.name)
          
          if (value !== undefined) {
            let type = question.getType()
            let inputType = question.inputType

            if (
              type == 'text' &&
              inputType == 'datetime-local'
            ) {
              let date = new Date(value)
              let offset = date.getTimezoneOffset()

              /**
               * The datetime is in UTC and has to be converted to local time for SurveyJs
               */
              value = dateFormat(new Date(date.getTime() + -offset * 60000), "yyyy-mm-dd'T'HH:MM:ss")
            }
            
            this.survey.setValue(question.name, value)
          }
        })
      }
    }
  },

  async mounted() {
    await this.open(this.dialogObj.options)
  },

  async destroyed() {
    if (this.checkTimeoutTimeout) {
      clearTimeout(this.checkTimeoutTimeout)
    }

    if (this.survey) {
      if (this.dialogType == 'main' && this.resultId) {
        await api.call('unlockSourceViewSurveyResult', {
          sourceName: this.$store.state.source.name,
          viewName: this.view.name,
          surveyId: this.surveyObj.id,
          resultId: this.resultId,
          params: {
            timeoutKey: localStorageHelper.getTimeoutKey()
          }
        })
      }

      this.survey.dispose()
      this.survey = null
    }
  }
}
</script>

<style lang="scss" scoped>
$tabsSidebarWidth: 370px;

// make tab items stretch to full height
:deep {
  .data-entry-dialog  {
    overflow-y: visible;
  }

  .sv_body {
    padding: 0 !important;
    border: none !important;
  } 

  .v_window, .v-window__container, .v-window-item, .v-item-group {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
  }
}


.tabs-sidebar {
  display: flex;
  flex: 0 0 $tabsSidebarWidth;
  box-shadow: none;
  border-left: 1px solid rgb(233, 233, 233);
}
</style>