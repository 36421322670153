<template>
   <md-dialog :md-active.sync="dialogVisible"  @md-clicked-outside="closeDialog(false)">
     <md-toolbar md-elevation="0" class="md-title md-primary">{{ $t('labels.variables') }}</md-toolbar>
     <div style="height: 420px; margin: 24px 32px; display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 32px;">
        <div style="display: flex; flex-direction: column; width: 300px;">
          <div class="md-subheading item-list-title" style="margin-bottom: 0; height: 32px">{{ $t('labels.available') }}</div>
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
               <md-field class="search-field">
                 <label>Search</label>
                <md-input ref="availableSearchTextInput" @focus="availableSearchText = availableSearchText || ''" @blur="availableSearchText = availableSearchText || null" size=0 v-model="availableSearchText"></md-input>
                 <md-icon>search</md-icon>
              </md-field>
              <md-button @click="changeAvailableSorting()" class="md-icon-button" style="top: 8px; margin: 0;">
                <md-icon v-if="availableSorting === 'asc'" style="color: #888;">arrow_downward</md-icon>
                <md-icon v-else-if="availableSorting === 'desc'" style="color: #888;">arrow_upward</md-icon>
                <md-icon v-else style="color: #888;">sort_by_alpha</md-icon>
              </md-button>
          </div>
          <md-content class="md-scrollbar item-list item-select-list">
              <draggable group="group" :list="filteredAvailableListItems" :sort="false" @change="onAvailableListChange" style="height: 100%">
                <div class="list-group-item dnd-list-item md-content" v-for="item in filteredAvailableListItems" :key="item.name || item">
                  <div>
                    <md-icon class="drag-indicator">drag_indicator</md-icon>
                  </div>
               <!--   <variable-badges v-bind:variable="item"></variable-badges> -->
                  <div style="overflow: hidden; text-overflow: ellipsis;">{{ getLocalizedLabel(item) }}
                    <!-- <md-tooltip md-delay="500" v-if="getItemDescription(item)">{{getItemDescription(item)}}</md-tooltip> -->
                  </div>
                </div>
              </draggable>
          </md-content>
        </div>
        <div style="display: flex; flex-direction: column; width: 300px;">
          <div class="md-subheading item-list-title" style="height: 32px; margin-bottom: 52px;">{{ $t('labels.selected') }}</div>
          <md-content class="md-scrollbar item-list">
            <draggable group="group" :list="selectedListItems" style="height: 100%">
              <div class="list-group-item dnd-list-item" v-for="selItem in selectedListItems" :key="selItem.name || selItem">
                <div>
                  <md-icon class="drag-indicator">drag_indicator</md-icon>
                </div>
              <!--  <variable-badges v-bind:variable="itemNameMap[selItem.name || selItem]"></variable-badges> -->
                <div>{{ getLocalizedLabel(selItem) }}
                 <!-- <md-tooltip md-delay="500">{{itemNameMap[selItem.name || selItem].description}}</md-tooltip> -->
                </div>
              </div>
            </draggable>
          </md-content>
        </div>
      </div>
      <md-dialog-actions>
        <md-button @click="closeDialog(true)">{{ $t('actions.cancel') }}</md-button>
        <md-button class="md-primary" @click="closeDialog(false)">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
</template>


<script>

export default {
  name: 'VariablesSelectionDialog',
  props: {
    variablesNameLabelMap: Object,
    availableVariables: Array,
    selVarNames: {
      type: Array,
      default: () => []
    },
    dialogVisible: Boolean
  },
  data() {
    return {
      availableListItems: [],
      selectedListItems: [],
      availableSorting: null,
      availableSearchText: null,
      filteredAvailableListItems: []
    }
  },
  methods: {
    onAvailableListChange(a) {
      this.updateAvailableItems();
    },
    closeDialog(cancel) {
      this.$emit('closed', cancel ? null : this.selectedListItems);
    },
    getLocalizedLabel(variableName) {
      return this.variablesNameLabelMap[variableName];
    },
    changeAvailableSorting() {
      if (this.availableSorting === 'asc') {
        this.availableSorting = 'desc';
      } else if (this.availableSorting === 'desc') {
        this.availableSorting = null;
      } else {
        this.availableSorting = 'asc';
      }
      this.updateAvailableItems();
    },
    updateAvailableItems() {
      let selListItems = new Set(this.selectedListItems || []);
      let availableListItems = [];
      for (let variable of this.availableVariables) {
        if (!selListItems.has(variable.name)) {
          availableListItems.push(variable.name);
        }
      }
      let listItems = availableListItems.slice();
      let itemLabelMap = {};
      listItems.forEach(item => itemLabelMap[item] = this.getLocalizedLabel(item).toLowerCase());
      let searchText = (this.availableSearchText || '').toLowerCase();
      if (searchText !== '') {
        let searchRegex = new RegExp(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        listItems = listItems.filter((item) => {
          return searchRegex.test(itemLabelMap[item]) &&
              !this.selVarNames.includes(item);
        });
      }
      let sortDir = this.availableSorting === 'asc' ? 1 : this.availableSorting === 'desc' ? -1 : 0;
      if (sortDir !== 0) {
        listItems = listItems.slice();
        listItems =  sortDir === 0 ? listItems : listItems.sort((itemA, itemB) => {
          let labelA = itemLabelMap[itemA];
          let labelB = itemLabelMap[itemB];
          return labelA < labelB ? sortDir : labelA > labelB ? -sortDir : 0;
        });

      }
     this.filteredAvailableListItems = listItems;
    }
  },
  watch: {
    dialogVisible(b) {
      if (b) {
        this.selectedListItems = this.selVarNames == null ? [] : this.selVarNames.slice();
        this.updateAvailableItems();
      }
    },
    selectedListItems() {
      this.updateAvailableItems();
    },
    availableSearchText() {
      this.updateAvailableItems();
    },
    availableSorting() {
      this.updateAvailableItems();
    }
  }
}

</script>


<style lang="scss" scoped>

  .setting-container {
    padding: 0;
    border-left: 4px solid transparent;
  }

  .setting-container.error {
    border-left-color: red;
  }

  .item-list-title {
    color: $primary;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-field {
    transition: width 200ms;
    width: 100%;
    min-height: 32px;
    margin: 0 16px 0 8px;
  }

  .item-list {
    flex: 1 1 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    border: 1px solid #ccc;
  }

  .item-list2 {
    max-height: 116px;
    margin: 4px 8px 24px;
    border-color: #bbb !important;
  }

  .list-group-item {
    font-size: 14px;
    border-top: 1px solid #eee;
    margin-top: -1px;
    padding: 4px 12px 4px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-overflow: ellipsis;
    cursor: default;
  }

  .dnd-list-item {
    padding-left: 0;
    cursor: grab;
  }

  .dnd-list-item>div:first-of-type {
    padding-right: 8px;
  }

  .dnd-list-item:hover {
    background-color: #f2f2f2;
  }

 .list-group-item .drag-indicator {
   color:#ccc;
 }

  .md-list-item-container {
    color: inherit;
  }

  .list-group-item .md-list-item-content {
    color: green;
    min-height: 40px;
  }

  .error-icon {
    color: red !important;
  }

  .list-edit-button {
    margin-left: auto;
    opacity: 0.2;
    transition: opacity 0.4s;
    transform: scale(0.9);
  }

  .md-layout-item:hover .list-edit-button {
    opacity: 1;
  }

  .text-field {
    min-height: 32px;
    margin: 4px 8px 24px;
    padding-top: 0;
    width: auto;
  }

  .text-field label {
      top: 7px;
  }

  .md-focused.text-field label {
      top: 7px;
      opacity: 0;
      color: inherit;
      font-weight: inherit;
      text-transform: inherit;
  }

  .ratio-list-item {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    text-overflow: ellipsis;
  }

  .ratio-list-item:not(:first-of-type) {
    border-top: 1px solid #ddd;
  }

  .parameter-list-item {
    padding: 4px 12px 4px 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .parameter-list-item:last-of-type {
    padding-bottom: 12px;
  }

  .menu-select {
      width: 100%;
      margin-top: 5px;
      cursor: default;
  }

  .menu-select>div:first-child {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 10px;
    border: 1px solid #bbb;

  }

  .menu-select .md-button {
    margin-left: auto;
    min-width: 28px;
    width: 28px;
    height: 28px;
  }

  .menu-select-field::after {
    display: none;
  }

</style>