import api from '@/services/api'
import store from '@/store/index'
import error from '@/services/error'
import { Device } from '@twilio/voice-sdk'

class PhoneCall {
  phoneState = 'not_calling'; // calling|not_calling|incoming_call
  callState = ''; // Anrufen, Anruf, Verbunden, Aufgelegt, Abgelehnt, Eingehenden Anruf
  twilioDevice = null;
  twilioDeviceIdentity = '';
  callObject = null;
  incomingCallObject = null;
  to = '';
  recordState = ''; // Aufnahme
  fromNumber = '';
  startTime = null;
  duration = 0;
  twilioCallSID = '';
  resultObject = null;
  evtTwilioMessage = null;

  constructor() {
    
  }

  async call(phoneNumber, resultObject) {
    try {
      this.sseCallEvents()

      this.phoneState = 'calling'
      this.resultObject = resultObject
  
      if (phoneNumber.substr(0,1) === '0') {
        phoneNumber = '+49' + phoneNumber.substr(1)
      }
      let to = phoneNumber

      var params = { 
          sourceName: store.state.source.name,
          viewName: store.state.view.name,
          ToNumber: to,
          callingDeviceIdentity: this.twilioDeviceIdentity,
          fromNumber: this.fromNumber
      };

      if (this.twilioDevice) {
        // Twilio.Device.connect() returns a Call object
        this.callObject = await this.twilioDevice.connect({ params })
        this.to = to
        this.callState = 'Anrufen'

        // add listeners to the Call
        // "accepted" means the call has finished connecting and the state is now "open"
        this.callObject.on("accept", () => {this.afterAccept('Anrufen')})
        this.callObject.on("disconnect", () =>  {this.afterDisconnect('Aufgelegt')})
        this.callObject.on("cancel", () => {this.afterDisconnect('Aufgelegt')})
        this.callObject.on("reject", () => {this.afterDisconnect('Abgelehnt')})

        this.callObject.on("messageReceived", (message) => {
          // console.log(JSON.stringify(message.content))
          // console.log('voiceEventSid: ', message.voiceEventSid)

          if (message.content.callStatus) {
            if (message.content.callStatus === 'ringing') {
              this.callState = 'Freizeichen'
            }
            else if (message.content.callStatus === 'in-progress') {
              this.callState = 'Verbunden'
            }
          }
        })        
      } else {
        // console.log("Unable to make call.")
      }    
    } catch(e) {
      error.runtimeError(e)
    }
  }

  hangUp() {
    this.afterDisconnect('Aufgelegt')

    if (this.callObject) {
      this.callObject.disconnect()
      this.callObject = null
    }
  }

  afterDisconnect(callState) {
    this.phoneState = 'not_calling'

    if (callState === 'Aufgelegt') {
      const callEnded = ['Besetzt', 'Anruf fehlgeschlagen']
      if (callEnded.includes(this.callState) === true) {
        callState = callState + '/' + this.callState
      }
    }
    this.callState = callState
    this.to = ''
    this.recordState = ''
    this.resultObject = null
    this.twilioCallSID = ''
  }

  afterAccept(callState) {
      this.callState = callState
      this.startTime = new Date()
      this.twilioCallSID = this.callObject.parameters.CallSid
      //console.log('phonecall.js; afterAccept;  this.twilioCallSID: ' + this.twilioCallSID )

      if (this.resultObject && store.state.view) {
        let id = this.resultObject._id
        let data = {}
        let toNumber = this.to
        let toNumberMasked = ''

        for (let index = toNumber.length; index >= 0; index--) {
          let char = toNumber.split('') [index-1]
          if (toNumber.length - index > 2) {
            toNumberMasked = '#' + toNumberMasked
            break
          }
          toNumberMasked = char + toNumberMasked
        }

        data =
          {
            twilioSID: this.twilioCallSID,
            to: toNumberMasked,
            startTime: this.startTime,
          }
        
        store.dispatch('updateSourceResultDescription', {
          id: id,
          action: 'addCall',
          data: data
        })
      }  
  }

  async recordCall() {  
    let options = {
      data: {
        callSID: this.twilioCallSID,
        id : this.resultObject._id,
        question: 'Here should be the project question stored'
      }
    }

    this.recordState = 'Aufnahme'
    //let data = (await api.call('twilioRecord', {to: this.to})).data    
    let data = (await api.call('twilioRecord', options)).data    
  }

  async stopRecord() {
    if (this.recordState === 'Aufnahme') {
      let options = {
        data: {
          twilioCallSID: this.twilioCallSID,
        }
      }
  
      this.recordState = ''

      let data = (await api.call('twilioRecordStop', options)).data    
    }
    this.recordState = ''
  }

  // Listen for Twilio.Device states
  addDeviceListeners() {
    this.twilioDevice.on("registered", function () {
        //log("Twilio.Device Ready to make and receive calls!");
    });

    this.twilioDevice.on("error", function (error) {
        //log("Twilio.Device Error: " + error.message);
    });

    this.twilioDevice.on("incoming", this.handleIncomingCall);

    this.twilioDevice.audio.on("deviceChange", this.updateAllAudioDevices.bind(this.twilioDevice));

    // Show audio selection UI if it is supported by the browser.
    if (this.twilioDevice.audio.isOutputSelectionSupported) {
        //audioSelectionDiv.classList.remove("hide");
    }
  }

  // Instantiate a new Twilio.Device
  intitializeDevice(token) {
    //log("Initializing device");
    this.twilioDevice = new Device(token, {
        debug: true,
        answerOnBridge: true,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ["opus", "pcmu"],
    })

    this.addDeviceListeners()

    // Device must be registered in order to receive incoming calls
    this.twilioDevice.register()

  }

  handleIncomingCall(call) {
    /*
    log(`Incoming call from ${call.parameters.From}`);

    //show incoming call div and incoming phone number
    incomingCallDiv.classList.remove("hide");
    incomingPhoneNumberEl.innerHTML = call.parameters.From;

    //add event listeners for Accept, Reject, and Hangup buttons
    incomingCallAcceptButton.onclick = () => {
        acceptIncomingCall(call);
    };

    incomingCallRejectButton.onclick = () => {
        rejectIncomingCall(call);
    };

    incomingCallHangupButton.onclick = () => {
        hangupIncomingCall(call);
    };
    */
    this.phoneState = 'incoming_call' 
    this.callState = 'Eingehenden Anruf'    
    // add event listener to call object
    call.on("cancel", handleDisconnectedIncomingCall());
    call.on("disconnect", handleDisconnectedIncomingCall());
    call.on("reject", handleDisconnectedIncomingCall());

    this.incomingCallObject = call
  }

  // ACCEPT INCOMING CALL

  acceptIncomingCall(call) {
    call.accept();
    this.callObject = call;
    this.phoneState = 'calling' 
    this.callState = 'Verbunden'    
    /*
    //update UI
    log("Accepted incoming call.");
    incomingCallAcceptButton.classList.add("hide");
    incomingCallRejectButton.classList.add("hide");
    incomingCallHangupButton.classList.remove("hide");
    */
  }

  // REJECT INCOMING CALL

  rejectIncomingCall(call) {
    call.reject();
      /*
      log("Rejected incoming call");
      resetIncomingCallUI();
      */
     this.incomingCallObject = null;
     this.phoneState = 'not_calling' 
     this.callState = ''    
   }

  // HANG UP INCOMING CALL

  hangupIncomingCall(call) {
      call.disconnect();
      this.incomingCallObject = null;
      this.phoneState = 'not_calling' 
      this.callState = ''    
       /*
      log("Hanging up incoming call")
      resetIncomingCallUI();
      */
  }

  // HANDLE DISCONNECTED INCOMING CALL

  handleDisconnectedIncomingCall() {
    this.incomingCallObject = null;
    this.phoneState = 'not_calling' 
    this.callState = ''    
   /*
      log("Incoming call ended.");    
      resetIncomingCallUI();
      */
  }

    // AUDIO CONTROLS

    /*
  async function getAudioDevices() {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    updateAllAudioDevices.bind(device);
  }
  */

  updateAllAudioDevices() {
    if (this.twilioDevice) {
      /*
      updateDevices(speakerDevices, this.twilioDevice.audio.speakerDevices.get());
      updateDevices(ringtoneDevices, this.twilioDevice.audio.ringtoneDevices.get());
      */
    }
  }

  updateOutputDevice() {
    /*
    const selectedDevices = Array.from(speakerDevices.children)
        .filter((node) => node.selected)
        .map((node) => node.getAttribute("data-id"));

    this.twilioDevice.audio.speakerDevices.set(selectedDevices);
    */
  }

  updateRingtoneDevice() {
    /*
      const selectedDevices = Array.from(ringtoneDevices.children)
          .filter((node) => node.selected)
          .map((node) => node.getAttribute("data-id"));

      this.twilioDevice.audio.ringtoneDevices.set(selectedDevices);
      */
  }

  bindVolumeIndicators(call) {
      call.on("volume", function (inputVolume, outputVolume) {
        /*
          var inputColor = "red";
          if (inputVolume < 0.5) {
              inputColor = "green";
          } else if (inputVolume < 0.75) {
              inputColor = "yellow";
          }

          inputVolumeBar.style.width = Math.floor(inputVolume * 300) + "px";
          inputVolumeBar.style.background = inputColor;

          var outputColor = "red";
          if (outputVolume < 0.5) {
              outputColor = "green";
          } else if (outputVolume < 0.75) {
              outputColor = "yellow";
          }

          outputVolumeBar.style.width = Math.floor(outputVolume * 300) + "px";
          outputVolumeBar.style.background = outputColor;
          */
      });
  }

  async updateRecordings() {
    let recordingsList
    recordingsList = await (api.call('twilioGetRecordings', {})).data    

    for (let recording of recordingsList) {                                            
        let link = 'https://api.twilio.com'+ recording["uri"];
        link = link.replace('.json', '')             
        //recordingsDiv.innerHTML += `<a href=" ${link}"> ${recording["recordingLabel"]} </a> <br>` ;
    }       
  }

  sseCallEvents() {
    // add listeners for the server events
    this.evtTwilioMessage = new EventSource("https://aux-woenenn-node-server-d44b10ca49aa.herokuapp.com/api/twilio/sse/" + this.twilioDeviceIdentity 
      , {
          withCredentials: false
        }
    )
    
    this.evtTwilioMessage.onmessage = (event) => {   
      //"answered busy canceled completed failed in-progress initiated no-answer queued ringing"             
      // console.log('event.data: ' + event.data)

      const newCallState = event.data

      if (newCallState === 'initiated') {
        this.callState = 'Anrufen'
      }
      else if (newCallState === 'ringing') {
        this.callState = 'Freizeichen'
      }
      else if (newCallState === 'in-progress') {
        this.callState = 'Verbunden'
      }
      else if (newCallState === 'completed') {
        this.callState = 'Aufgelegt'
      }
      else if (newCallState === 'answered') {
        this.callState = 'Verbunden'
      }
      else if (newCallState === 'busy') {
        this.callState = 'Besetzt'
      }
      else if (newCallState === 'canceled') {
        this.callState = 'Anruf fehlgeschlagen'
      }
      else if (newCallState === 'failed') {
        this.callState = 'Anruf fehlgeschlagen'
      }
      else if (newCallState === 'no-answer') {
        this.callState = 'Aufgelegt'
      }
      else if (newCallState === 'queued') {
        this.callState = 'Anrufen'
      }
    } // this.evtTwilioMessage.onmessage

    this.evtTwilioMessage.addEventListener("connection_opened", (event) => {
      //console.log('connection_opened /n' +  JSON.stringify(event.data) )
    })

    this.evtTwilioMessage.addEventListener('open', function(event) {
       //console.log("Connection opened");
    }, false)      

    this.evtTwilioMessage.addEventListener('error', function(event) {
      if (event.target.readyState == EventSource.CLOSED) {
        // console.log("Disconnected from /stream");
      } else if (event.target.readyState == EventSource.CONNECTING) {
        // console.log('Connecting to /stream');
      }
    }, false)
    // ** ENDE ** add listeners for the server events

  }
  // Update the available ringtone and speaker devices
  /*
  updateDevices(selectEl, selectedDevices) {
    selectEl.innerHTML = "";

    this.twilioDevice.audio.availableOutputDevices.forEach(function (device, id) {
      var isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(function (device) {
          if (device.deviceId === id) {
              isActive = true;
          }
      });

      var option = document.createElement("option");
      option.label = device.label;
      option.setAttribute("data-id", id);
      if (isActive) {
          option.setAttribute("selected", "selected");
      }
      selectEl.appendChild(option);
    });
  }
  */
} /***************** class PhoneCall ********************/

async function initPhoneCall() {
  let phoneCall = null
  //log("Requesting Access Token...");
  try {
    let device;
    let token;
    let callingDeviceIdentity;

    phoneCall = new PhoneCall();
    let data = (await api.call('twilioToken', {})).data;    
   
    token = data.token;
    phoneCall.twilioDeviceIdentity = data.identity;
    phoneCall.intitializeDevice(token);

    return phoneCall;
  } catch (err) {
    console.log(err);
    //log("An error occurred. See your browser console for more information.");
  }
}

export { initPhoneCall }