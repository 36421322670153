<template>
<div class="input-treeselect">
  <div class="select-all-container">
    <md-checkbox class="select-all-checkbox" :value="selectedIds && selectedIds.length === 0"
                 @change="changeAllSelection()"
                 :indeterminate="selectedIds != null && selectedIds.length !== filteredOptions.length"></md-checkbox>
    <span>{{ $t('labels.all_items') }}</span>
  </div>
  <treeselect
    ref="treeselect"
    name="demo"
    :multiple="multiple"
    :clearable="true"
    :searchable="false"
    :always-open="true"
    :options.sync="filteredOptions"
    :limit="3"
    :max-height="284"
    v-model="selectedIds"
    @input="valueChanged"
    :loadOptions="loadOptions"
    :default-expand-level="1"
    :auto-load-root-options="false"
    noResultsText="Keine Optionen verfügbar"
    noChildrenText="Keine Optionen verfügbar"
    noOptionsText="Keine Optionen verfügbar"
    loadingText="Lade..."
    placeholder="Auswählen..."
  />
</div>
</template>

<script>
import _ from 'lodash'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from '@/services/api'

export default {
  name: 'InputTreeselect',
  components: { Treeselect },
  props: {
    filter: Object,
    sorting: String,
    searchText: String
  },
  data() {
    return {
      // define default value
      multiple: true,
      selectedIds: null,
      filteredOptions: null
      // non-reactive data
      //options: null,
    }
  },
  beforeMount() {
    // Set options in case we have some saved in the store
    // const state = this.$store.state
    // const treeLevels = this.filter.treeLevels
    // let options = null
    // if (state.filterTreeData[this.filter.name]) {
    //   options = state.filterTreeData[this.filter.name]
    // }
    // this.options = options
    this.options = []
  },
  methods: {
    changeAllSelection() {
      let select = this.selectedIds == null ||
          (this.filteredOptions && this.selectedIds.length !== this.filteredOptions.length)
      let selectedIds = []
      if (select && this.filteredOptions) {
        this.filteredOptions.forEach(o => {
          selectedIds.push(o.id)
        })
      }
      this.selectedIds = selectedIds
      this.valueChanged(selectedIds)
    },
    valueChanged(selectedIds) {
      let valueChanges = {}
      selectedIds.map(id => {
        let splits = id.split(':')
        if (!valueChanges[splits[0]]) valueChanges[splits[0]] = []
        valueChanges[splits[0]].push(splits[1])
      })
      const checkFilterVarNames = this.filter.treeLevel || [this.filter.name]
      if (checkFilterVarNames) {
        checkFilterVarNames.map(filterVarName => {
          // If we have a nested tree we make sure that the values of
          // filter var levels are updated, i.e. we fill the once not
          // selected with an empty array
          // Unnested tree just check for the filter var name itself
          if (!valueChanges[filterVarName])
            valueChanges[filterVarName] = []
        })
      }
      this.$emit('change', valueChanges)
    },
    loadOptions({ action, parentNode, callback }) {
      const filter = this.filter
      const filterValues = this.$store.state.filterValues
      let params = {}
      for (let key in filterValues) {
        let filterValue = filterValues[key]
        if (Array.isArray(filterValue) ? filterValue.length > 0 : filterValue) {
          params[key] = filterValue
        }
      }    

      const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }

      // Are we on the first level, i.e. no parentNode?
      if (!parentNode) {        

        api.call('getSourceViewFilter', {
          sourceName: this.$store.state.source.name,
          viewName: this.$store.state.view.name,
          filterVarName: filter.name,
          params
        }, filterVariable => {
          /*
          const treeLevels = filter.treeLevels
          const hasChildren = treeLevels && treeLevels.length > 1
          let options = []
          filterVariable.options.map(o => {
            let label = filter.verbatimVar ? `${o.value}  [${numberWithCommas(o.count)} Verbatims]` : `${o.value}  [N = ${numberWithCommas(o.count)}]`
            let isFiltered = false
            if (this.searchText) {
              let searchRegex = new RegExp(this.searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
              isFiltered = !searchRegex.test(label)
            }
            if (!isFiltered) {
              options.push({
                id: filter.name + ':' + o.value,
                label: label,
                value: o.value,
                name: filter.name,
                childrenName: hasChildren ? treeLevels[1] : undefined,
                children: hasChildren ? null : undefined
                // null will trigger loadOptions (see treeselect documentation)
              })
            }
          })
          // Sort children if sorting is set
          if (this.sorting === 'asc') {
            options.sort((a, b) => a.label.localeCompare(b.label))
          } else if (this.sorting === 'desc') {
            options.sort((a, b) => -a.label.localeCompare(b.label))
          }
          this.options = options
          */

          this.options = filterVariable.options
          this.filteredOptions = this.filterAndSortOptions()
          if (callback) callback()
          this.updateSelectedIdsForFilter(filterVariable)
        })

      // We are in a sub level of the tree
      } else {
        //ToDo:
        throw new Error("Sublevels in Tree View not implemented")
        /*
        const treeLevels = filter.treeLevels
        const currentLevel = treeLevels.indexOf(parentNode.childrenName)
        let hasChildren = true
        if (currentLevel === treeLevels.length - 1) {
          hasChildren = false
        }

        // Add parent node name to filter
        params[parentNode.name] = parentNode.value

        api.call('getSourceViewFilter', {
          sourceName: this.$store.state.source.name,
          viewName: this.$store.state.view.name,
          filterVarName: parentNode.childrenName,
          params
        }, filterVariable => {
          let children = []
          if (filter.options) {
            filterVariable.options.map(o => {
              let label = filter.verbatimVar ? `${o.value}  [${numberWithCommas(o.count)} Verbatims]` : `${o.value}  [N = ${numberWithCommas(o.count)}]`
              let isFiltered = false
              if (searchText) {
                let searchRegex = new RegExp(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
                isFiltered = !searchRegex.test(label)
              }
              if (!isFiltered) {
                children.push({
                  id: parentNode.childrenName + ':' + o.value,
                  label: label,
                  value: o.value,
                  name: parentNode.childrenName,
                  childrenName: hasChildren ? treeLevels[currentLevel + 1] : undefined,
                  children: hasChildren ? null : undefined
                  // null will trigger loadOptions (see treeselect documentation)
                })
              }
            })
          }          
          // Sort children if sorting is set
          if (sorting === 'asc') {
            children.sort((a, b) => a.label.localeCompare(b.label))
          } else if (sorting === 'desc') {
            children.sort((a, b) => -a.label.localeCompare(b.label))
          }

          parentNode.children = children
          if (callback) callback()
          this.updateSelectedIdsForFilter(filterVariable)
        })
        */
      }
    },
    filterAndSortOptions() {
      const options = this.options
      const filter = this.filter
      const treeLevels = filter.treeLevels
      const hasChildren = treeLevels && treeLevels.length > 1

      const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }

      let filteredOptions = []
      if (options) {
        options.forEach(o => {
          let label = filter.verbatimVar ? `${o.value}  [${numberWithCommas(o.count)} Verbatims]` : `${o.value}  [N = ${numberWithCommas(o.count)}]`
          let isFiltered = false
          if (this.searchText) {
            let searchRegex = new RegExp(this.searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
            isFiltered = !searchRegex.test(label)
          }
          if (!isFiltered) {
            filteredOptions.push({
              id: filter.name + ':' + o.value,
              label: label,
              value: o.value,
              name: filter.name,
              childrenName: hasChildren ? treeLevels[1] : undefined,
              children: hasChildren ? null : undefined
              // null will trigger loadOptions (see treeselect documentation)
            })
          }
        })
        // Sort children if sorting is set
        if (this.sorting === 'asc') {
          filteredOptions.sort((a, b) => a.label.localeCompare(b.label))
        } else if (this.sorting === 'desc') {
          filteredOptions.sort((a, b) => -a.label.localeCompare(b.label))
        }
      }
      return filteredOptions
    },
    updateSelectedIdsForFilter(filterVariable) {
      const extendIdsFor = (ids, filterVarName, value) => {
        _.each(value[filterVarName], v => {
          ids.push(filterVarName + ':' + v)
        })
      }

      const filter = this.filter
      const filterValues = this.$store.state.filterValues

      let selectedFilters = filterValues[filter.verbatimVar ? filter.verbatimVar + '.fractions.' + filterVariable.name : filterVariable.name]

      // If value does not exist, create it
      // Happens with sublevel tree options
      if (selectedFilters) {
        // For every currently selected option ...
        let valuesToPull = []
        selectedFilters.map(selected =>{
          // ... check if this option should be still available
          if (!_.find(filterVariable.options, { value: selected })) {
            // If not, remove options from filter values
            valuesToPull.push(selected)
          }
        })

        // Pull values
        _.pull(selectedFilters, ...valuesToPull)

        // Build selected ids based on filter value
        let ids = []
        let filterVariableName = filter.verbatimVar ? filter.verbatimVar + '.fractions.' + filterVariable.name : filterVariable.name
        if (filterValues) {
          if (this.treeLevels) {
            _.each(this.treeLevels, filterVarName => {
              extendIdsFor(ids, filterVariableName, filterValues)
            })
          } else {
            extendIdsFor(ids, filterVariableName, filterValues)
          }
        }

        this.selectedIds = ids
        this.valueChanged(ids)

      }
    },
    updateFilterTreeDataStore() {
      // We save the options in the store so that
      // next time we load the select tree these data is
      // already available
      this.$store.dispatch('setFilterTreeData', {
        filterVarName: this.filter.name,
        options: this.filteredOptions
      })
    }
  },
  watch: {
    searchText () {
      this.filteredOptions = this.filterAndSortOptions()
    },
    sorting () {
      this.filteredOptions = this.filterAndSortOptions()
    }
  }
}
</script>

<style lang="scss">
.input-treeselect {

  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked:hover,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate:hover,
  .vue-treeselect__checkbox--indeterminate,
  .vue-treeselect__checkbox--checked,
  .vue-treeselect__checkbox--checked:hover,
  .vue-treeselect__checkbox--unchecked:hover {
    border-color: $red;
    background: $red;
  }
  .vue-treeselect__menu {
    border: 1px solid #eee;
    border-radius: 0;
    min-height: 320px;
  }
  .vue-treeselect__control {
    display: none;
  }
  .vue-treeselect__icon-warning {
    background: $red;
  }

  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked:hover {
    border-color: $red;
  }
  .vue-treeselect__icon-loader::before {
    background: $red-light;
  }
  .vue-treeselect__icon-loader::after {
    background: $red;
  }
  .vue-treeselect--open-below .vue-treeselect__menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-all-container {
    display: flex;
    align-items: center;
  }

  .select-all-checkbox {
    margin: 0 5px 0;
    transform: scale(0.7);
    
    &.md-checked .md-checkbox-container {
      background: var(--md-theme-default-primary-on-background, #cd041e);
      border-color: var(--md-theme-default-primary-on-background, #cd041e);
      border-radius: 4px;
    }

    &.md-indeterminate .md-checkbox-container:after {
      border-color: white;
    }

  }

}


</style>
