<template>
  <div>
    <confirm-dialog ref="confirmDialog" />

    <v-btn depressed color="primary" @click="openDialog" :disabled="question.isReadOnly">
      {{ question.buttonText || 'Survey Editor' }}
    </v-btn>
  </div>
</template>

<script>
import surveyCreator from '@/services/surveyCreator'

export default {
  name: 'surveyjs-creator-form',
  data: () => ({
    bulkWoenennQuestionVariables: null,
    ready: false
  }),
  props: {
    css: Object,
    question: Object
  },
  async beforeMount() {
    this.bulkWoenennQuestionVariables = {
      delete: {},
      insert: {},
      replace: {}
    }

    let dataEntryWidgetInterface = this.question.survey._widgetInterface

    // need to check for dataEntryWidgetInterface because the widget is also used
    // in the preview of the survey creator
    dataEntryWidgetInterface && dataEntryWidgetInterface.onSave.add(async data => {
      data.__SUPPLEMENTAL = {
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables
      }
    })
    
    this.ready = true
  },
  methods: {
    async openDialog() {
      let returnObj = await surveyCreator.open({
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables,
        question: this.question,
        surveyDefinition: this.question.value
      })

      if (!returnObj.cancel) {
        await this.save(returnObj.surveyDefinition)
      }
    },

    async save(surveyDefinition) {
      this.question.value = surveyDefinition
    }
  }
}
</script>

<style lang="scss" scoped>
  // TEMP: Layout fixes
  .spg-question__content {
    position: relative;
  }

  .sv_q_dropdown_clean-button {
    display: none;
  }
</style>
