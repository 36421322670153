<template>
<div v-if="loading" class="__woenenn-loading-wrapper">
  <v-overlay :opacity=".3" >
    <div style="display: flex; flex-direction: column; align-items: center;">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <div class="text-h1" style="padding: 10px 0 0;">{{ $t('texts.LOADING') }}</div>
    </div>
  </v-overlay>
</div>
</template>

<script>
export default {
  name: 'LoadingView',

  props: ['loading'],

  mounted() {
    document.body.insertBefore(this.$el, document.body.firstChild)
  }
}
</script>

<style lang="scss">
.__woenenn-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
</style>
