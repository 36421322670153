<template>
  <v-card v-if="surveys" flat style="display: flex; flex: 1 0 0;">
    <v-card-text class="pa-0" style="position: relative; display: flex; flex: 1 0 0;">
      <v-data-table
        :headers="headers"
        :items="tableData"
        disable-pagination
        hide-default-footer
        fixed-header
        height="100%"
        item-key="name"
        :search="search"
        style="flex: 1 1 0;"
        class="data-table"
        @click:row="selectQuestion"
      >
        <template v-slot:top>
          <v-toolbar flat style="padding: 0 0 5px; background: #f0f0f0;">
            <v-text-field
              v-model="search"
              rounded
              background-color="white"
              prepend-icon="mdi-magnify"
              single-line
              hide-details
              label="Search"
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import * as SurveyKnockoutUi from "survey-knockout-ui"

import surveyJsUtil from '@/services/surveyjs/util'

const HEADERS = [
  {
    text: 'Survey',
    value: 'surveyTitle',
    fixed: true
  },
  {
    text: 'Question',
    value: 'questionLabel'
  },
  {
    text: 'Name',
    value: 'questionName',
    fixed: true
  },
  {
    text: 'Description',
    value: 'questionDescription'
  },
  {
    text: 'Type',
    value: 'questionType'
  }
]

export default {
  name: 'QuestionSelect',
  data: () => ({
    tableData: [],
    headers: HEADERS,
    search: ''
  }),
  props: {
    surveys: null
  },
  methods: {
    selectQuestion(tableDataRow) {
      this.$emit(`selectQuestion`, tableDataRow.question) // sets the input value (v-model handling)
    }
  },
  async beforeMount() {
    this.tableData = []

    let surveys = this.surveys

    // need to unregister functions so function like "lookup"
    // are not executed
    surveyJsUtil.unregisterFunctions()

    for (let i = 0; i < surveys.length; ++i) {
      let surveyDefinition = surveys[i].surveyDefinition
      let survey = new SurveyKnockoutUi.Model(surveyDefinition)
      let questions = survey.getAllQuestions()

      for (let j = 0; j < questions.length; ++j) {
        let question = questions[j]

        this.tableData.push({
          question: question,
          questionDescription: question.description,
          questionLabel: question.title,
          questionName: question.name,
          questionType: question.getType(),
          survey: survey,
          surveyTitle: survey.title
        })
      }
    }

    surveyJsUtil.registerFunctions()
  }
}
</script>

<style scoped lang="scss">
  .data-table {
    :deep{
      table {
        table-layout: fixed;
      }
    }
  }
</style>