import store from '@/store'
import { LOCAL_STORAGE_VERSION } from '@/config'
import { getInstance } from '@/auth'
import { v4 as uuidv4 } from 'uuid'

let getLastStorageName = () => {
  const auth = getInstance()

  return 'lastAnalysisStore_' + auth.user.sub
}

let clear = () => {
  localStorage.removeItem('sourceSettings')
  localStorage.removeItem('variables')
  localStorage.removeItem('viewVariables')
  localStorage.removeItem('sourceVariables')
  localStorage.removeItem(getLastStorageName())
  localStorage.setItem('VERSION', LOCAL_STORAGE_VERSION)
}

let checkVersion = () => {
  if (localStorage.getItem('VERSION') != LOCAL_STORAGE_VERSION) {
    clear()
    localStorage.setItem('VERSION', LOCAL_STORAGE_VERSION)
  }
}

let getEmptySourceSettings = () => {
  return {
    roleName: null,
    sourceName: null,
    viewNames: {},
    VERSION: LOCAL_STORAGE_VERSION
  }
}

let getSourceSettings = () => {
  checkVersion()

  let jsonSourceSettings = localStorage.getItem('sourceSettings')
  let sourceSettings = jsonSourceSettings ? JSON.parse(jsonSourceSettings) : {}

  return Object.assign(
    getEmptySourceSettings(),
    sourceSettings
  )
}

let setSourceSettings = (sourceSettings) => {
  checkVersion()

  localStorage.setItem('sourceSettings', JSON.stringify(sourceSettings))

  return sourceSettings
}

let localStorageHelper = {
  clear() {
    clear()
  },

  getLastAnalysisCache() {
    return localStorage.getItem(getLastStorageName())
  },

  getRoleName() {
    let sourceSettings = getSourceSettings()
    return sourceSettings.roleName
  },

  getSource() {
    let sourceSettings = getSourceSettings()
    let sourceName = sourceSettings.sourceName

    if (sourceName) {
      let source = store.state.sources.find(source => sourceName === source.name)

      if (source) {
        return source
      } else {
        sourceSettings.sourceName = null
        delete sourceSettings.viewNames[sourceName]
        setSourceSettings(sourceSettings)
      }
    }
    
    return null
  },

  getSourceValue(key, defaultValue) {
    checkVersion()
  
    let sourceVariables = localStorage.getItem('sourceVariables')
    sourceVariables = sourceVariables ? JSON.parse(sourceVariables) : {}
    let value = _.get(sourceVariables, `${store.state.source.name}.${key}`)
    if (value == undefined && defaultValue !== undefined) {
      value = defaultValue
      _.set(sourceVariables, `${store.state.source.name}.${key}`, value)
      localStorage.setItem('sourceVariables', JSON.stringify(sourceVariables))
    }
    return value
  },

  getTimeoutKey() {
    let timeoutKey = store.state.timeoutKey || localStorage.getItem('timeoutKey_v1.0')

    if (!timeoutKey) {
      timeoutKey = uuidv4()
      localStorageHelper.setTimeoutKey(timeoutKey)
    }

    return timeoutKey
  },

  getValue(key, defaultValue) {
    checkVersion()
  
    let variables = localStorage.getItem('variables')
    variables = variables ? JSON.parse(variables) : {}
    let value = variables[key]
    if (value == undefined && defaultValue !== undefined) {
      value = defaultValue
      variables[key] = value
      localStorage.setItem('viewVariables', JSON.stringify(variables))
    }
    return value
  },

  getViewName(sourceName, roleName) {
    let sourceSettings = getSourceSettings()

    roleName = roleName || '_NONE'

    let viewName = _.get(sourceSettings, ['viewNames', sourceName, roleName])
    
    if (viewName) {
      let view = store.state.views.find(view => viewName === view.name)

      if (view) {
        return viewName
      } else {
        delete sourceSettings.viewNames[sourceName]
        setSourceSettings(sourceSettings)                                                                                                                                                                                                                                                     
      }
    }
    
    return null
  },

  getViewValue(key, defaultValue) {
    checkVersion()
  
    let viewVariables = localStorage.getItem('viewVariables')
    viewVariables = viewVariables ? JSON.parse(viewVariables) : {}
    let value = _.get(viewVariables, `${store.state.source.name}.${store.state.view.name}.${key}`)
    if (value == undefined && defaultValue !== undefined) {
      value = defaultValue
      _.set(viewVariables, `${store.state.source.name}.${store.state.view.name}.${key}`, value)
      localStorage.setItem('viewVariables', JSON.stringify(viewVariables))
    }
    return value
  },

  setLastAnalysisCache(lastAnalysisCache) {
    localStorage.setItem(getLastStorageName(), JSON.stringify(lastAnalysisCache))
  },

  setSourceName(sourceName) {
    let sourceSettings = getSourceSettings()
    sourceSettings.sourceName = sourceName
    setSourceSettings(sourceSettings)
  },

  setTimeoutKey(timeoutKey) {
    localStorage.setItem('timeoutKey_v1.0', timeoutKey)
    store.dispatch('setTimeoutKey', timeoutKey)
  },

  setViewName(sourceName, roleName, viewName) {
    let sourceSettings = getSourceSettings()

    roleName = roleName || '_NONE'

    _.set(sourceSettings, ['viewNames', sourceName, roleName], viewName)

    setSourceSettings(sourceSettings)
  },
  
  setRoleName(roleName) {
    let sourceSettings = getSourceSettings()
    sourceSettings.roleName = roleName
    setSourceSettings(sourceSettings)
  },

  setSourceValue(key, value) {
    checkVersion()
  
    let sourceVariables = localStorage.getItem('sourceVariables')
    sourceVariables = sourceVariables ? JSON.parse(sourceVariables) : {}
    _.set(sourceVariables, `${store.state.source.name}.${key}`, value)
    localStorage.setItem('sourceVariables', JSON.stringify(sourceVariables))
  },

  setValue(key, value) {
    checkVersion()
  
    let variables = localStorage.getItem('variables')
    variables = variables ? JSON.parse(variables) : {}
    variables[key] = value
    localStorage.setItem('variables', JSON.stringify(variables))
  },

  setViewValue(key, value) {
    checkVersion()
  
    let viewVariables = localStorage.getItem('viewVariables')
    viewVariables = viewVariables ? JSON.parse(viewVariables) : {}
    _.set(viewVariables, `${store.state.source.name}.${store.state.view.name}.${key}`, value)
    localStorage.setItem('viewVariables', JSON.stringify(viewVariables))
  },

  updateValue(key, defaultValue, callback) {
    localStorageHelper.setValue(key, callback(localStorageHelper.getValue(key, defaultValue)))
  },

  updateSourceValue(key, defaultValue, callback) {
    localStorageHelper.setSourceValue(key, callback(localStorageHelper.getSourceValue(key, defaultValue)))
  },

  updateViewValue(key, defaultValue, callback) {
    localStorageHelper.setViewValue(key, callback(localStorageHelper.getViewValue(key, defaultValue)))
  }
}

export default localStorageHelper