<template>
  
  <div ref="slider" id="slider" class="slider">
    <div class="slider-track">
      <div class="slider-track-overlay"></div>
    </div>
    <div class="slider-indicator" v-bind:style="{ left: percentValue + '%' }">
      <div class="slider-indicator-overlay"></div>
    </div>
    <div class="slider-event-target" @focus="setParentFocus($event, true)" @blur="setParentFocus($event, false)" @mousemove="updateFromMouseEvent" @mousedown="startDrag" @mouseup="endDrag" tabindex=0></div>
  </div>

</template>
  

<script>

export default {
  label: 'Slider',
  props: {
    'value': Number,
    'steps': {
      default: 0,
      type: Number
    },
    'min': {
      default: 0,
      type: Number
    },
    'max': {
      default: 100,
      type: Number
    },
    'precision': {
      default: 0,
      type: Number
    }
  },
  data: () => {
    return {
      isDragging: false,
      reverse: false,
    }
  },  
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    setParentFocus(event) {

    },
    startDrag(event) {
      if (event.buttons === 1 && !this.isDragging) {
        this.isDragging = true;
        event.target.focus();
        document.addEventListener('mousemove', this.docMousemove);
        document.addEventListener('mouseup', this.endDrag);
        this.isDragging = true;
        this.updateFromMouseEvent(event);
      }
    },
    endDrag(event) {
      document.removeEventListener('mousemove', this.docMousemove);
      document.removeEventListener('mouseup', this.endDrag);
      this.isDragging = false;
    },
    updateFromMouseEvent: function (event) {
      if (this.isDragging) {
        let element = this.$refs.slider;
        // determine value from mouse or touch x position
        // For touch events ("touchstart", "touchmove", "touchend") we have to get the pageX from the original event
        var rect = element.getBoundingClientRect();
        var x = event.clientX - rect.left; //x position within the element.
        
        let min = this.min;
        let max = this.max;
        let value = Math.min(max, Math.max(min, min + (max - min) * x / element.offsetWidth));
        let precision = this.precision;
        let precision10 = Math.pow(10, precision);
        // apply precision
        value = Math.round(value * precision10) / precision10;
        // update model
        value = this.reverse ? max - value : value;
        value = this.snapToStep(value);
        if (value !== this.percentValue) {
          this.$emit('input',  value);
        }
      }
    },
    docMousemove: function (event) {
      if (event.buttons === 1 || event.type === 'touchmove') {
        event.stopPropagation();
        event.preventDefault(); // prevents other elements from being selected if mouse is dragged outside the element
        this.updateFromMouseEvent(event);
      } else if (this.isDragging) {
        // no buttons are pressed during mouse move, probably because no mouse up event was fired.
        // One case when this can happen is if the
        // user switches the active window while dragging.
        this.endDrag();
      }
    },
    snapToStep(value) {
      let steps = this.steps;
      if (steps != null && steps !== 0) {
        let min = this.min;
        let max = this.max;
        let valueNormal = Math.round(steps * (value - min) / (max - min)) / steps;
        value = valueNormal * (max - min) + min;
      }
      return value;      
    },
     
  },
  computed: {
    percentValue() {
      let min = this.min;
      let max = this.max;
      let reverse = false;
      let percent = 100 * (this.value - min) / (max - min);
      if (reverse) {
        percent = 100 - percent;
      }
      return percent;      
    }
  }
}
</script>


<style lang="scss" >


.slider {
    position: relative;
    display: block;
    height: 24px;
    width: 200px;  /* Currently the slider does not react on size changes, therefore we need a constant width in any case */
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
}

.slider-track {
    background-color: #757575;
    position: absolute;
    left: 0;
    top: 11px;
    width: 100%;
    height: 2px;
}

.slider-track-overlay,
.slider-indicator-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider-indicator {
    background-color: #888;
    position: absolute;
    top: 5px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    border-radius: 50%;
    transition: 0s ease;
    transition-property: left, width, height, top, margin-left;
    transition-duration: 0.0s, 0.1s, 0.1s, 0.1s, 0.1s;
}

.slider:hover > .slider-indicator {
    top: 4px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
}

.slider.focused > .slider-indicator {
    top: 0;
    width: 24px;
    height: 24px;
    margin-left: -12px;
}

.slider-indicator-overlay {
    border-radius: 50%;
}

.slider-event-target {
    position: absolute;
    cursor: ew-resize;
    width: calc(100% + 14px);
    left: calc(0px - 7px);
    top: 0;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
}
</style>