import Vue from 'vue'
import { CustomWidgetCollection, ComponentCollection, JsonObject, Serializer } from "survey-core"
import _ from 'lodash'

import store from '@/store'
import i18n from '@/i18n'
import vuetify from '@/plugins/vuetify'

const dateWidget = {
  name: 'date',
  title: 'Date',
  iconName: "survey-creator-icon-datepicker",
  widgetIsLoaded: () => true,
  isFit: question => question.getType() === 'date',
  activatedByChanged: activatedBy => JsonObject.metaData.addClass('date', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: function(question, el) {
    // Fix visibility element in legacy theme so date is not partly hidden
    let legacyThemeElement = el.closest(".sv_q")
    if (legacyThemeElement) {
      legacyThemeElement.style.overflow = 'visible'
    }

    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question,
        el: el
      }),
      vuetify,
      template: '<surveyjs-creator-survey-date :question="question" :el="el" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
  }
}

const htmlWidget = {
  name: 'html-rendered',
  iconName: 'survey-creator-icon-html',
  title: 'Html',
  widgetIsLoaded: () => true,
  isFit: question => question.getType() === 'html-rendered',
  activatedByChanged:  activatedBy => JsonObject.metaData.addClass('html-rendered', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  afterRender: (question, el) => el.innerHTML = question.value
}

const jsonWidget = {
  name: 'json',
  iconName: 'survey-creator-icon-json',
  title: 'JSON',
  widgetIsLoaded: () => true,
  hasErrors: () => true,
  isFit: question => question.getType() === 'json',
  activatedByChanged:  activatedBy => JsonObject.metaData.addClass('json', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question
      }),
      vuetify,
      template: '<surveyjs-creator-json :question="question" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
  }
}

const vueWidget = {
  name: 'vue',
  iconName: 'survey-creator-icon-vue',
  title: 'HTML Composer',
  widgetIsLoaded: () => true,
  hasErrors: () => true,
  isFit: question => question.getType() === 'vue',
  activatedByChanged:  activatedBy => JsonObject.metaData.addClass('vue', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question
      }),
      vuetify,
      template: '<surveyjs-creator-vue :question="question" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
  }
}

const surveyJsCreatorWidget = {
  name: 'surveyjs-creator',
  title: 'SurveyJs Creator',
  iconName: 'survey-creator-icon-datepicker',
  widgetIsLoaded: () => true,
  hasErrors: () => true,
  isFit: question => {
    return question.getType() === 'surveyjs-creator'
  },
  activatedByChanged:  activatedBy => JsonObject.metaData.addClass('surveyjs-creator', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question
      }),
      vuetify,
      template: '<surveyjs-creator-form :question="question" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
   }
}

const surveyJsCreatorIsVerbatimWidget = {
  name: 'surveyjs-creator-is-verbatim',
  title: 'SurveyJs Creator is verbatim',
  widgetIsLoaded: () => true,
  isFit: question => question.name == 'isVerbatim',
  activatedByChanged: activatedBy => JsonObject.metaData.addClass('surveyjs-creator-is-verbatim', [], null, 'text'),
  isDefaultRender: true,
  htmlTemplate: '<template />',
  init() {
    Serializer.addProperty('paneldynamic', {
      name: 'isVerbatim',
      displayName: 'VERBATIM',
      category: "general",
      visibleIndex: 0
    })
  },
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question,
        el: el
      }),
      vuetify,
      template: '<surveyjs-creator-is-verbatim :question="question" :el="el" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
  }
}

const surveyJsCreatorIsWoenennQuestionVariableWidget = {
  name: 'surveyjs-creator-is-woenenn-question-variable',
  title: 'SurveyJs Creator is WOENENN Question Variable',
  widgetIsLoaded: () => true,
  isFit: question => question.name == 'is-woenenn-question-variable',
  activatedByChanged: activatedBy => JsonObject.metaData.addClass('surveyjs-creator-is-woenenn-question-variable', [], null, 'text'),
  isDefaultRender: true,
  htmlTemplate: '<template />',
  init() {
    Serializer.addProperty('question', {
      name: 'is-woenenn-question-variable',
      displayName: 'WOENENN',
      category: "general",
      visibleIndex: 0
    })
  },
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question,
        el: el
      }),
      vuetify,
      template: '<surveyjs-creator-is-woenenn-question-variable :question="question" :el="el" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
  }
}

const SurveyJsCreatorRelatedSurvey = {
  name: 'related-survey',
  title: 'Related Survey',
  iconName: "survey-creator-icon-related-survey",
  widgetIsLoaded: () => true,
  isFit: question => {
    return question.getType() === 'related-survey' 
  },
  activatedByChanged: activatedBy => JsonObject.metaData.addClass('related-survey', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question,
        el: el
      }),
      vuetify,
      template: '<surveyjs-creator-related-surveys :question="question" :multiple="false" :el="el" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
   }
}

const SurveyJsCreatorRelatedSurveys = {
  name: 'related-surveys',
  title: 'Related Surveys',
  iconName: "survey-creator-icon-related-surveys",
  widgetIsLoaded: () => true,
  isFit: question => {
    return question.getType() === 'related-surveys' 
  },
  activatedByChanged: activatedBy => JsonObject.metaData.addClass('related-surveys', [], null, 'text'),
  isDefaultRender: false,
  htmlTemplate: '<template />',
  afterRender: (question, el) => {
    let vue = new Vue({
      el,
      store,
      i18n,
      data: () => ({
        question: question,
        el: el
      }),
      vuetify,
      template: '<surveyjs-creator-related-surveys :question="question" :el="el" :multiple="true" />'
    })

    question.survey.disposeCallback = () => vue.$destroy()
   }
}

const surveyJsUtil = {
  init() {
    surveyJsUtil.addCustomProperties()
    surveyJsUtil.addCustomTypes()
    surveyJsUtil.addCustomWidgets()
  },

  addCustomProperties() {
    Serializer.addProperty('question', {
      name: '_comment',
      displayName: 'Comment',
      type: 'text',
      category: "general",
      visibleIndex: 4
    })

    Serializer.addProperty('page', {
      name: '_comment',
      displayName: 'Comment',
      type: 'text', 
      category: "general",
      visibleIndex: 3
    })

    Serializer.addProperty('survey', {
      name: '_comment',
      displayName: 'Comment',
      type: 'text',
      category: "general",
      visibleIndex: 2
    })

    // Set default value for "Clear invisible value" property o 'none'
    // https://github.com/tema-q/woenenn-vue-client/issues/992
    Serializer.findProperty('survey', 'clearInvisibleValues').defaultValue = 'none'
  },

  addCustomTypes() {
    return
    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: "related-survey", 
      // A display name used in the Toolbox
      title: "Related Survey", 
      // A JSON schema for the base question type (Dropdown in this case)
      /*questionJSON: {
        "type": "dropdown",
        "placeholder": "Select a country...",
        "choicesByUrl": {
          "url": "https://surveyjs.io/api/CountriesExample",
        }
      }*/
    })
  },

  addCustomWidgets() {
    CustomWidgetCollection.Instance.add(dateWidget, "customtype")
    CustomWidgetCollection.Instance.add(htmlWidget, "customtype")
    CustomWidgetCollection.Instance.add(jsonWidget, "customtype")
    CustomWidgetCollection.Instance.add(surveyJsCreatorWidget)
    CustomWidgetCollection.Instance.add(surveyJsCreatorIsWoenennQuestionVariableWidget, "customtype")
    CustomWidgetCollection.Instance.add(surveyJsCreatorIsVerbatimWidget, "customtype")
    CustomWidgetCollection.Instance.add(SurveyJsCreatorRelatedSurvey, "customtype")
    CustomWidgetCollection.Instance.add(SurveyJsCreatorRelatedSurveys, "customtype")
    CustomWidgetCollection.Instance.add(vueWidget, "customtype")
  }
}

export default surveyJsUtil