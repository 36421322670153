import { t } from '@/i18n'
import dateFormat from 'dateformat'
import util from '@/services/util'
import _ from 'lodash'

import store from '@/store/index'
import error from '@/services/error'

const BATCH_SIZE = 100
const BATCH_SERVER_SIZE = 1000

const DEFAULT_SORTERS = [{
  colId: '_id',
  sort: 'asc'
}]

function formatField(val, type, format) {
  switch (type) {
    case 'date':
      val = (val && Date.parse(val)) ? dateFormat(new Date(val), format || "yyyy-mm-dd") : null
      break
    case 'datetime':
      val = (val && Date.parse(val)) ? dateFormat(new Date(val), format || "yyyy-mm-dd HH:MM") : null
      break
    case 'number':
      val = format ? Math.floor(val * format) / format : val
      break
  }
  return val
}

async function getRows(sortModel, startRow, endRow, verbatimVar, fields, ds) {
  try {
    sortModel = sortModel || []

    let sorters = sortModel.length > 0 ? sortModel : DEFAULT_SORTERS

    // to minimize server requests get at least as many records for the next batch 
    let batchEnd = Math.ceil(endRow / BATCH_SIZE) * BATCH_SIZE

    let sortResults = await store.dispatch('getSourceViewSortedResults', {
      sorters: sorters,
      end: batchEnd,
      verbatimVar: verbatimVar
    })

    // only user records in current batch
    sortResults = sortResults.slice(startRow, endRow)

    // ag-grid data
    let tableData = []

    // iterate through all results and expand for each fraction
    sortResults.forEach(([sortResult, index]) => {
      if (verbatimVar) {
        // support dot notations for verbatimVar
        // e.g. 'status_info.status' for object { status: { info:  ... } }
        let fraction = _.get(sortResult, verbatimVar).fractions[index]
        
        let tableRowData = {
          __result: sortResult
        }

        tableRowData.verbatim = fraction.verbatim

        fields.forEach(field => {
          if (field.key === '_id') {
            tableRowData.id = sortResult._id + ':' + (index + 1)
            tableRowData._id = sortResult._id + ':' + (index + 1)
            return
          }
          if (field.isVerbatim) {
            tableRowData[field.key] = formatField(_.get(fraction, field.name), field.type, field.format)
          } else {
            tableRowData[field.key] = formatField(_.get(sortResult, field.name), field.type, field.format)
          }    
        })

        tableData.push(tableRowData)
      } else {
        let tableRowData = {
          __result: sortResult
        }

        fields.forEach(field => {
          if (field.key === '_id') {
            tableRowData.id = sortResult._id
            tableRowData._id = sortResult._id
            return
          }
          if (!field.isVerbatim) {
            tableRowData[field.key] = formatField(_.get(sortResult, field.name), field.type, field.format)
          }
        })

        tableData.push(tableRowData)
      }
    })
    
    // include table row num
    tableData.forEach((tableDataRow, i) => {
      tableDataRow.__num = i + startRow + 1
      tableDataRow.action = i + startRow + 1
    })

    let lastPos = endRow + 200 < ds ? endRow + 200 : ds

    return { tableData, lastPos }
  } catch(e) {
    error.runtimeError(e)
  }
}

const results = {
  async getRowsBatch(sortModel, startRow, endRow, timeout, showProgress, verbatimVar, fields) {
    let currentStartRow = startRow
    let currentEndRow = Math.min(endRow - startRow, BATCH_SERVER_SIZE) + startRow
    let completeTableData = []
    let tableData, lastPos

    let getProgress = percent => {
      return {
        title: t('progress.exporting_datasets'),
        percent: percent
      }
    }

    let ds = store.state.sortedResultMeta.ds
    endRow = Math.min(endRow, ds)

    showProgress && store.dispatch('setProgress', getProgress(0))

    while (true) {
      ({ tableData, lastPos } = await getRows(sortModel, currentStartRow, currentEndRow, verbatimVar, fields, ds))

      completeTableData = completeTableData.concat(tableData)
      currentStartRow = currentEndRow
      currentEndRow = Math.min(endRow, currentStartRow + BATCH_SERVER_SIZE)

      showProgress && store.dispatch('setProgress', getProgress(((currentEndRow - startRow) / (endRow - startRow)) * 100))

      if (currentStartRow >= endRow) {
        break
      }

      timeout && await util.asyncTimeout(timeout)        
    }

    // give the user a second to see full progress
    await util.asyncTimeout(1000)

    showProgress && await store.dispatch('setProgress', null)

    return { tableData: completeTableData, lastPos }
  }
}

export default results