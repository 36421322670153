/**
 *
 * Draws the specified svg on a canvas and returns the canvas.
 *
 * @param svgStr
 * @param width
 * @param height
 * @returns {Promise<HTMLCanvasElement>}
 */
async function svgToCanvas(svgStr, width, height) {
  let blob = new Blob([svgStr], {type:'image/svg+xml;charset=utf-8'});
  let svgUrl = URL.createObjectURL(blob);
  let svgImage = new Image();
  svgImage.src = svgUrl;
  return svgImage.decode().then(() => {
    URL.revokeObjectURL(svgUrl);
    let canvas = document.createElement('canvas');
    canvas.width = width != null ? width : svgImage.clientWidth;
    canvas.height = height != null ? height : svgImage.clientHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(svgImage, 0, 0, canvas.width, canvas.height);
    return canvas;
  });
}

export {svgToCanvas};