import { Grid } from 'ag-grid-community'
import 'ag-grid-enterprise'
import store from '@/store/index'
import dateFormat from 'dateformat'
import jspdf from "jspdf"
import { t, getLocale } from '@/i18n'
import gridRenderers from '@/services/aggrid/gridRenderers'
import _ from 'lodash'

export default {
  exportFile(columnDefs, rowData, type, locale) {
    // let the grid know which columns and what data to use
    let gridOptions = {
      columnDefs: columnDefs,
      rowData: rowData
    }

    // lookup the container we want the Grid to use
    let eGridDiv = document.createElement('div')

    // create the grid passing in the div to use together with the columns & data we want to use
    let exportGrid = new Grid(eGridDiv, gridOptions)
    
    let exportOptions = {
      allColumns: true,
      sheetName: store.state.view.name,
      fileName:  this.generateFileName(),
      processCellCallback: function(params) {
        return gridRenderers.renderExport(params.column.userProvidedColDef.cellRenderer, params.value, locale)
      }
    }

    switch (type) {
      case 'Excel':
          exportGrid.gridOptions.api.exportDataAsExcel(exportOptions)    
        break
      case 'CSV':
          exportGrid.gridOptions.api.exportDataAsCsv(exportOptions)
        break
    }

    exportGrid.destroy()
  },

  generateFileName() {
    // Generate file name (without file extension)
    return 'Export_' + store.state.view.name + '_' + dateFormat(Date.now(), "yyyymmdd")
  },

  exportExcel(columnDefs, rowData) {
    this.exportFile(columnDefs, rowData, 'Excel')
  },

  exportCSV(columnDefs, rowData) {
    this.exportFile(columnDefs, rowData, 'CSV')
  },

  exportPDF(columnDefs, rowData, exportSettings, locale) {
    let pdfSettings = (exportSettings && exportSettings.pdf) || {};
    let headerText = pdfSettings.header || '';
    let footerText = pdfSettings.footer || '';
    let titleText = pdfSettings.title || '';
    

    //Note: jsPdf uses a fixed 1/3 descent to ascent ratio for text. Ascent is always 
    //      calculated as 75% of the text height.
    // All page units are in Millimeter
    const inch2mm = 25.4;
    const point2mm = inch2mm / 72;  // 72 points == 1 inch
    
    let lineHeightFactor = 1.2;
    
    // Header and footer get one empty line below/above their text
    let headerFontSize = 10;
    let footerFontSize = 10;
    let headerTextLines = headerText.split(/\\n/);
    let headerMarginTop = 0.5 * inch2mm;
    // text height + 0.75 text ascent; 1: space below line
    let headerHeight = (headerTextLines.length + 1) * (headerFontSize * lineHeightFactor * point2mm);
    let footerTextLines = (footerText || '').split(/\\n/);
    let footerMarginBottom = 0.5 * inch2mm;
    // 0.3: line distance above footer text; 0.5: Space above line
    let footerHeight = (footerTextLines.length  + 0.3 + 0.5) * (footerFontSize * lineHeightFactor * point2mm);
    let pageWidth = 210;
    let pageHeight = 297;
    // Word default margins: top/bottom: 2.54cm, left/right: 3.17 cm
    let marginLeft = 1.5 * inch2mm;
    let marginRight = 1.5 * inch2mm;
    let contentMarginTop = headerHeight + headerMarginTop;
    let contentMarginBottom = footerTextLines.length > 0 ? footerHeight + footerMarginBottom : 1 * inch2mm;
    
    let contentWidth = pageWidth - marginLeft - marginRight;
    let contentFontSize = 11;
    let contentLineHeight = lineHeightFactor * contentFontSize * point2mm;    
    
    var pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // orientation: portrait, unit: millimeter, size: A4
    let currentY = contentMarginTop + 0.75 * contentLineHeight;
    
    let addText = function(pdf, text) {
      var lines = pdf.splitTextToSize(text, contentWidth);
      for (var i = 0; i < lines.length; i++) {
        if (currentY > pageHeight - 0.25 * contentLineHeight - contentMarginBottom) {
          // start a new page
          pdf.addPage();
          currentY = contentMarginTop + 0.75 * contentLineHeight;  
        }
        pdf.text(lines[i], marginLeft, currentY, {lineHeightFactor: lineHeightFactor});
        currentY += contentLineHeight;
      }
    }

    // write content
    pdf.setFontSize(contentFontSize);
    for (let i = 0; i < rowData.length; i++) {
      let rowDataItem = rowData[i];
      
      for (let j = 0; j < columnDefs.length; j++) {
        let columnDef = columnDefs[j]
        let columnField = columnDef.field;
        if (columnField !== '__num') {
          let variableLabel = columnDef.headerName;
          let variableValue = rowDataItem[columnField];

          if (columnDef.cellRenderer === gridRenderers.VerbatimCellRenderer) {
            currentY += 2;
            pdf.setFont(undefined, 'normal');

            addText(pdf, gridRenderers.renderExport(columnDef.cellRenderer, variableValue, locale));

            currentY += 2;
          } else {
            pdf.setFont(undefined, 'bold');

            addText(pdf, variableLabel + ': ' + gridRenderers.renderExport(columnDef.cellRenderer, variableValue, locale));
          }
        }
      }

      currentY += 5;
    }

    // add header and footer for each page
    let totalPageCount = pdf.internal.getNumberOfPages();
    pdf.setFont(undefined, 'normal');
    for (let currentPage = 1; currentPage <= totalPageCount; currentPage++) {
      pdf.setPage(currentPage);
      // add header
      pdf.setFontSize(headerFontSize);
      let headerTextY = headerMarginTop + headerFontSize * 0.75 * point2mm;
      pdf.text(t('texts.EXPORT_PAGE') + ' ' + currentPage + '/' + totalPageCount, pageWidth - marginRight, headerTextY, {align: 'right'});
      pdf.text(headerTextLines, marginLeft, headerTextY, {lineHeightFactor: lineHeightFactor});  
      let headerLineY = headerMarginTop + headerTextLines.length * (headerFontSize * lineHeightFactor * point2mm);
      pdf.line(marginLeft, headerLineY, pageWidth - marginRight, headerLineY);
      // add footer (only if footer text is not empty)
      if (footerTextLines.length > 0) {
        pdf.setFontSize(footerFontSize);
        let footerTextY = pageHeight - footerMarginBottom - (footerTextLines.length - 0.75) * (footerFontSize * lineHeightFactor * point2mm);
        pdf.text(footerTextLines, pageWidth - marginRight, footerTextY, {align: 'right', lineHeightFactor: lineHeightFactor});
        let footerLineY = pageHeight - footerMarginBottom - (footerTextLines.length + 0.3) * (footerFontSize * lineHeightFactor * point2mm);
        pdf.line(marginLeft, footerLineY, pageWidth - marginRight, footerLineY);  
      }      
    }

    pdf.setProperties({
      title: titleText || '',
      creator: 'WOENENN by Tema-Q GmbH'
    });

    pdf.save(this.generateFileName() + '.pdf');
  }
}
