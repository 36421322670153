<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    persistent
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title v-show="!!title" class="text-h6 white--text primary">
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="!!message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.hideCancel"
          text
          @click="cancel"
        >
          {{ $t(options.cancelText) }}
        </v-btn>
        <v-btn
          color="primary"
          @click="agree"
        >
          <span v-html="options.agreeText" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ConfirmDialog",
    data() {
      let options = this.getOptions()

      return {
        dialog: false,
        promiseResolve: null,
        promiseReject: null,
        message: null,
        title: null,
        options: options
      }
    },
    props: ['dialogObj'],
    methods: {
      getOptions() {
        return {
          width: 400,
          zIndex: 200,
          cancelText: this.$t('actions.cancel'),
          agreeText: this.$t('actions.ok'),
          hideCancel: false
        }
      },

      open(title, message, options) {
        this.dialog = true
        this.title = title || this.$t('titles.confirm')
        this.message = message
        this.options = Object.assign(this.getOptions(), options || {})

        return new Promise((resolve, reject) => {
          this.promiseResolve = resolve
          this.promiseReject = reject
        })
      },
      agree() {
        this.promiseResolve(true)
        this.dialog = false
      },
      cancel() {
        this.promiseResolve(false)
        this.dialog = false
      }
    },

    async mounted() {
      if (this.dialogObj) {
        let returnVal = await this.open(this.dialogObj.title, this.dialogObj.message, this.dialogObj.options)

        this.dialogObj.resolve && this.dialogObj.resolve(returnVal) 
      }
    }
  }
</script>
