<template>
  <!-- 
How to change Vuetify v-icon color
In my case, didn't work putting the color's name, but it worked putting the hexadecimal value:
<v-icon color="#933">mdi-close</v-icon>
Despite the fact that in the documentation of the Vuetify icons, you find that it works by putting the names. 
But is required put v-app tag in vue template.
  -->
  <v-app>
  <div>
    <div id="tq-phone-call-dialog" v-if="showDialog">
      <v-btn v-if="showIncomingCall" icon @click="answer">
        <!-- 
          <v-icon large color="green darken-2">mdi-phone</v-icon> 
          Mit dense finde ich auch zu groß
        -->
        <v-icon color="green">mdi-phone</v-icon>
      </v-btn>
      <v-btn v-if="showIncomingHangUp" icon @click="$store.state.phoneCall.hangUp">
      <!--<v-icon large color="green darken-2">mdi-phone-hangup</v-icon>-->
        <v-icon color="red">mdi-phone-hangup</v-icon>
      </v-btn>
      {{'Anrufen: ' + $store.state.phoneCall ? $store.state.phoneCall.from : ''}}
    </div>
  </div>
  </v-app>
</template>

<script>
export default {
  name: 'PhoneCallDialog',
  props: {
  },
  data: Object,
  methods: {
    answer() {
      if ($store.state.phoneCall.incomingCallObject) {
        $store.state.phoneCall.acceptIncomingCall($store.state.phoneCall.incomingCallObject)
      }
    },
  },
  computed: {
    showIncomingCall() {
      if (this.$store.state.phoneCall) {
        return this.$store.state.phoneCall.phoneState === 'incoming_call'
      } else {return false}
    },
    showIncomingHangUp() {
      if (this.$store.state.phoneCall) {
        return this.$store.state.phoneCall.phoneState === 'calling'
      } else {return false}
    },
    showDialog() {
      if (this.$store.state.phoneCall) {
        return true
      } else {return false}
    },
  }
}
</script>

<style lang="scss" scoped>
  #tq-phone-call-dialog {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 100000;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
</style>
