import { getLocale } from '@/i18n';


function removeBaseValueFromColName(name) {
  // Labels for the last column var have the base appended // Labels for the last column var have the base appended (for example "Diesel (N=421)". This removes
  // the base and the surrounding brackets and leading whitespace.
  return name.replace(/\s*\(N=\d+\)/, '');
}
function getBaseValueFromColName(name) {
  // Extreact the base value from a column name if available (for example 421 for "Diesel (N=421)")
  let baseValMatch = name.match(/\(N=(\d+)\)/);
  if (baseValMatch != null) {
    return baseValMatch[1];
  } else {
    return null;
  }
}
function formatNumber(number, decimalPlaces) {
  if (number === '') {
    return null;
  } else {    
    number = parseFloat(number);
    return (number + (number >= 0 ? Number.EPSILON : -Number.EPSILON)).toFixed(decimalPlaces || 0);
  }
}

function deepMergeOptions(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  let isObject = function(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMergeOptions(target[key], source[key]);
      } else if (source[key] != null && source[key] !== '') {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMergeOptions(target, ...sources);
}


function tabulationResultToChartOptions(queryResult, queryData, settingsModel, variables, settingsChartOptions) {
  let variablesNameMap = {};
  for (let variable of variables) {
    variablesNameMap[variable.name] = variable;
  }
  let locale = getLocale();  

  // Use first selected ratio      
  let ratios = queryData.ratios;
  let ratio = ratios[0];
  
  let ratioName = ratio.name;
  let ratiosModel = settingsModel.find(function(el) {
    return el.name === 'ratios';
  });
  let ratioModel = ratiosModel.items.find(function(el) {
    return el.name === ratioName;
  });
  let ratioLabel = (ratioModel.label_int && ratioModel.label_int[locale]) || ratioModel.label;

  let chartOptions = {
    caption: {
      style: {
        fontSize: '14px'
      }
    },
    credits: {
      enabled: false,
      style: {
        fontSize: '12px'
      }
    },
    drilldown: {
      activeAxisLabelStyle: {
        textDecoration: 'none', // Remove underline from drilldown links
      }
    }, 
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        },
        dataLabels: {
          format: '{point.yRounded}'
        }
      }
    }
  };

  deepMergeOptions(chartOptions, settingsChartOptions);

  //console.log(JSON.stringify(chartOptions, null, 2))

  let showTotal = settingsChartOptions._other && settingsChartOptions._other.showTotal;
  
  let colVarCount = queryData.tabulation_columns.length;
  let rowVarCount = queryData.tabulation_rows.length;      
  let varCount = colVarCount + rowVarCount;

  
  let resultCols = queryResult.columns;    

  // build column tree model. Each node contains its index, name and an array of child nodes.
  let colNodeStack = [];
  let rootColNodes = [];
  let leafColNodes = [];
  for (let colIndex = resultCols.length - 1; colIndex >= 0; colIndex--) {
    let col = resultCols[colIndex];
    let colLevel;
    let colName;
    let baseValue;
    if (colVarCount === 1) {
      // column is a string
      let isTotal = col.startsWith('__Total');
      if (showTotal || !isTotal) {
        colName = isTotal ? 'Total' : removeBaseValueFromColName(col);
        baseValue = getBaseValueFromColName(col);
        colLevel = 0;           
      }
    } else {
      // Column is an array of strings. Iterate from last to first, using the first
      // non-total entry as the row name.
      for (let i = col.length - 1; i >= 0; i--) {
        let isTotal = col[i].startsWith('__Total');
        if ((showTotal && i === 0) || !isTotal) {
          colName = isTotal ? 'Total' : this.removeBaseValueFromColName(col[i]);
          colLevel = i;
          baseValue = this.getBaseValueFromColName(col[i]);
          if (!isTotal) {
            break;
          }
        }
      }
    }
    if (colName != null) {
      let colNode = {
        id: "c" + colIndex,
        name: colName,
        colIndex: colIndex,
        varLevel: colLevel,
        baseValue: baseValue,
        children: []
      };
      if (colLevel === colVarCount - 1) {
        leafColNodes.unshift(colNode);
      }
      if (colLevel === 0) {
        rootColNodes.unshift(colNode);
      } else {
        colNodeStack[colLevel - 1].children.unshift(colNode);
      }
      colNodeStack[colLevel] = colNode;        
    } 
  }

  // console.log(JSON.stringify(rootColNodes, null, 2))

  if (chartOptions.xAxis) {
    chartOptions.xAxis.type = 'category';
    let rowVar = variablesNameMap[queryData.tabulation_columns[0]];        
    chartOptions.xAxis.title = {text: rowVar.label};            
  } else {
    chartOptions.xAxis = {type: 'category'};      
  }

  if (chartOptions.yAxis == null) {
    chartOptions.yAxis = {};
  }
  chartOptions.yAxis.title = {text: ratioLabel};
  
  
  let ratioValues = queryResult[ratio.id];
  let resultRows = queryResult.rows;

  let rowNodeStack = [];
  let rootRowNodes = [];
  for (let rowIndex = resultRows.length - 1; rowIndex >= 0; rowIndex--) {
    let row = resultRows[rowIndex];
    let rowLevel;
    let rowName = null;
    if (rowVarCount === 1) {
      // row is a string
      let isTotal = row.startsWith('__Total');
      if (!isTotal) {
        rowName = isTotal ? 'Total' : row;
        rowLevel = 0;           
      }
    } else {
        // row is an array of strings. Iterate from last to first, using the first
        // non-total entry as the row name.
      for (let i = row.length - 1; i >= 0; i--) {
        let isTotal = row[i].startsWith('__Total');
        if (!isTotal) {
          rowName = isTotal ? 'Total' : row[i];
          rowLevel = i;
          if (!isTotal) {
            break;
          }
        }
      }
    }
    if (rowName != null) {
      let rowNode = {
        id: 'r' + rowIndex,
        name: rowName,
        rowIndex: rowIndex,
        rowLevel: rowLevel,
        children: []
      };
      if (rowLevel === 0) {
        rootRowNodes.unshift(rowNode);
      } else {
        if (rowNodeStack[rowLevel - 1] != null)
        rowNodeStack[rowLevel - 1].children.unshift(rowNode);
      }
      rowNodeStack[rowLevel] = rowNode;        
    } 
  }

  // console.log(JSON.stringify(rootRowNodes, null, 2))
  
  let hideZeroValues = false;

  // create data tree model by adding all root row nodes to each leaf column node. The column nodes are already in a tree structure if multiple colum variables exist.
  let copyRowNode = function(rowNode, colIndex) {
    let newChildNodes = rowNode.children.slice();
    let newNode = Object.assign({}, rowNode);
    newNode = Object.assign(newNode, {
        id: 'r' + rowNode.rowIndex + 'c' + colIndex, 
        colIndex: colIndex,
        varLevel: rowNode.rowLevel + colVarCount,
      });
    for (let childIndex = 0; childIndex < newChildNodes.length; childIndex++) {
      newChildNodes[childIndex] = copyRowNode(newChildNodes[childIndex], colIndex);
    }
    newNode.children = newChildNodes;
    return newNode;
  }

  for (let leafColNode of leafColNodes) {
    let colNodeIndex = leafColNode.colIndex;
    for (let rootRowNode of rootRowNodes) {
      leafColNode.children.push(copyRowNode(rootRowNode, colNodeIndex));
    }
  }


  let setNodeValue = function(node) {
    let colIndex = node.colIndex;
    let rowIndex = node.rowIndex;
    if (rowIndex == null) {
      rowIndex = ratioValues.length - 1;
    }
    node.value = ratioValues[rowIndex][colIndex];            
    if (node.children) {
      node.children.forEach(childNode => setNodeValue(childNode));
    }          
  }
  rootColNodes.forEach(node => setNodeValue(node));

  // Sort column nodes if sorting is selected
  let sortData = settingsChartOptions.plotOptions && settingsChartOptions.plotOptions.series && settingsChartOptions.plotOptions.series._sortData;
  if (sortData) {
    let totalValues = ratioValues[ratioValues.length - 1];  
    rootColNodes.sort((a, b) => {
      return totalValues[a.colIndex] - totalValues[b.colIndex];
    })
  }

  // Limit number of displayed groups if limit is set.
  let maxValueCount = settingsChartOptions._other && settingsChartOptions._other.displayedDataLim;
  let isDataReversed = settingsChartOptions.xAxis && settingsChartOptions.xAxis.reversed;
  if (maxValueCount != null ) {
    if (isDataReversed) {
      rootColNodes.splice(0, (rootColNodes.length - maxValueCount) - (showTotal ? 1 : 0));
    } else {
      rootColNodes.splice(maxValueCount, (rootColNodes.length - maxValueCount) - (showTotal ? 1 : 0));
    }
  }      

  // console.log(JSON.stringify(rootColNodes, null, 2));     

  // Number of decimal places for rounding
  let decimalPlaces = ratio.decimal_places;


  // Create drilldown series
  let drilldownSeries = [];
  let addDrilldown = (node, pathString) => {
    pathString = pathString ? pathString + ' » ' + node.name : node.name;
    let series = {
      id: node.id,
      name: pathString,
      data: [],
      /*events: {
        click: this.pointClick
      },*/
      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="font-size: 10px">{point.label}</span><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yRounded}</b>'
      }
    }
    drilldownSeries.push(series);
    for (let childNode of node.children) {
      let y = childNode.value;
      let baseValue = childNode.baseValue;          
      if (childNode.varLevel === varCount - 1) {
        if (!hideZeroValues || y !== 0) {
          series.data.push({
            id: childNode.id,
            name: childNode.name,
            y: y === '' ? null : y,
            yRounded: y === '' ? null : formatNumber(y, decimalPlaces),
            label: childNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')')
          })
        }
      } else {
        series.data.push({
          id: childNode.id,
          name: childNode.name,
          y: y === '' ? null : y,
          yRounded: y === '' ? null : formatNumber(y, decimalPlaces),
          label: childNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')'),
          drilldown: childNode.id
        })
        addDrilldown(childNode, pathString);
      }
    }
    // Sort data if sorting is selected
    if (sortData) {
      series.data.sort((a, b) => {
        return a.y - b.y;
      })
    }   
    // Limit number of displayed values if limit is set. 
    if (maxValueCount != null ) {
      if (isDataReversed) {
        series.data.splice(0, series.data.length - maxValueCount);
      } else {
        series.data.splice(maxValueCount);
      }
    }    
  }

  // Create series from data tree model.
  let series = [];
  let seriesNames = new Set();
  // Collect all possible names from child nodes of root col nodes
  for (let rootNode of rootColNodes) {
    rootNode.children.forEach(childNode => {
      seriesNames.add(childNode.name);
    });
  }
  // Create series from collected names.
  let idSeriesMap = new Map();
  for (let seriesName of seriesNames) {
    let seriesEntry = {
      name: seriesName,
      /*point: {
        events: {
          click: this.pointClick                  
        }
      },*/             
      data: [],
      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="font-size: 10px">{point.label}</span><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yRounded}</b>'
      },
    };
    series.push(seriesEntry);
    idSeriesMap.set(seriesName, seriesEntry);
  }

  // Add root nodes to series. Note that child nodes are becoming the series, which then again contain the root nodes.
  // This may be quite confusing and is the reason why the conversion between tabulation data and the highcharts is a bit difficult to understand.
  for (let rootNodeIndex = 0; rootNodeIndex < rootColNodes.length; rootNodeIndex++) {
    let rootColNode = rootColNodes[rootNodeIndex];
    for (let seriesEntry of series) {
      let childNode = rootColNode.children.find(e => seriesEntry.name === e.name);
      if (childNode == null) {
        // Complete missing data with empty data points. This will work around problems with order of root column nodes displayed in the chart.
        seriesEntry.data.push({
          y: 0,
          visible: false, 
          name: rootColNode.name
          });            
      } else {           
        // If no row index is provided, we are still in columns. Use grand total row (last row) in this case.
        let rowIndex = childNode.rowIndex == null ? resultRows.length - 1 : childNode.rowIndex;
        let y = childNode.value;
        let id = 'r' + rowIndex + rootColNode.id;
        let baseValue = rootColNode.baseValue;
        let dataItem = {
          id: id + '_',
          name: rootColNode.name,
          row: rowIndex,
          col: rootColNode.colIndex,
          y: y === '' ? null : y,
          yRounded: y === '' ? null : formatNumber(y, decimalPlaces),
          label: rootColNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')')
        };
        if (varCount > 2) {
          dataItem.drilldown = childNode.id;
          seriesEntry.data.push(dataItem);
          addDrilldown(childNode, rootColNode.name);
        }
        seriesEntry.data.push(dataItem);            
      }
    }
  }
  
  // Set series and drilldown series to options
  chartOptions.series = series;
  if (drilldownSeries && drilldownSeries.length > 0) {
    if (chartOptions.drilldown == null) {
      chartOptions.drilldown = {};
    }
    chartOptions.drilldown.series = drilldownSeries;
  }  
  
  if (varCount <= 2) {
    // Create and set categories
    let categories = [];
    for (let rootColNode of rootColNodes) {
      categories.push(rootColNode.name);
    }
    if (chartOptions.xAxis) { 
      chartOptions.xAxis.categories = categories;
    }
  } 
    
  // console.log(JSON.stringify(chartOptions, null, 4))

  let auxJSON = settingsChartOptions._other && settingsChartOptions._other.auxJSON;
  
  try {
    if (auxJSON != null && auxJSON !== '') {
      let data = JSON.parse(auxJSON);
      deepMergeOptions(chartOptions, data);
    }
  } catch (error) {
    console.error(error.stack, '\nJSON: "' + auxJSON + '"');
  }
  
  return chartOptions;
}

export {
  tabulationResultToChartOptions
};
