import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#cd041e',
        secondary: '#137DBF',
        accent: '#4B4B4B',
        error: '#b71c1c',
      },
    }
  }
});
