<template>
  <div class="views-container" v-if="ready">
    <div class="heading">{{ $t('texts.SECTIONS') }}</div>

    <v-list dense class="list">
      <v-list-item-group color="primary">
        <v-list-item
          class="list-item"
          v-for="(view, index) in $store.state.views"
          v-bind:key="index"
          @click="changeView(view)"
          v-bind:class="{ 'view-active': isViewActive(view) }"
        >
          <div class="list-badge">
            <letter-badge v-if="!view.verbatim" letter="S" label="Non-Verbatim" color="#77a1e5" :delay="1000"></letter-badge>
            <letter-badge v-if="view.verbatim" letter="V" label="Verbatim" color="#f3ab55" :delay="1000"></letter-badge>
          </div>

          <v-list-item-content>
            <v-list-item-title class="list-item-title">
              <v-tooltip bottom :offset-overflow="true" open-delay="1000" :disabled="!view.description">
                <template v-slot:activator="{ on, attrs }">
                  <div class="view-label" v-bind="attrs" v-on="on">{{view.label}}</div>
                </template>
                <span>{{view.description}}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="list-item-action">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="resetDefault(view)">
                  <v-list-item-content>Reset to default</v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon>mdi-undo</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'AnalysisViews',
  data: () => ({
    ready: false
  }),
  methods: {
    changeView(view) {
      this.$emit('changeView', view)
    },
    isViewActive(view) {
      return view.name === this.$store.state.view.name
    },
    resetDefault(view) {
      this.$emit('changeView', view, { resetDefault: true })
    }
  },
  created() {
    this.source = this.$store.state.source
    this.ready = true
  },
}
</script>

<style lang="scss" scoped>
  .views-container {
    flex: 1 0 0;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .view-active {
      background: #eee;
    }
  }

  .source-select {
    padding: 16px 16px 0;
  }

  .heading {
    color: $primary;
    flex: 0 0 auto;
    padding: 16px 16px 12px 16px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(221, 221, 221);
    white-space: nowrap;
    text-transform: uppercase;
  }

  .list {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;

    .list-item {
      font-size: 14px;
      padding: 0 5px 0 15px;
      border-top: 1px solid $border-grey;
    }

    .list-item-title {
      font-weight: 400 !important;
      font-size: 14px !important;
    }

    .list-badge {
      margin: 0 0 0 0;
    }

    .list-item:first-child {
      border-top: none;
    }

    .view-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0 0 5px;
    }
  }

  .list-item-action {
    margin: 0 !important;
  }
</style>