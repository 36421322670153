<template>
  <div class="vue-composer full-flex">
    <v-tooltip v-if="preview" top open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on" 
          icon
          @click="preview = false"
          class="preview-icon"
        >
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltips.SHOW_PREVIEW')}}</span>
    </v-tooltip>
    <v-tooltip v-if="!preview" top open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="preview = true"
          class="preview-icon"
        >
          <v-icon>mdi-code-tags</v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltips.SHOW_CODE')}}</span>
    </v-tooltip>
    <div v-show="!preview" class="full-flex">
      <v-card class="full-flex" elevation="0">
        <v-tabs v-model="activeTab" style="display: flex; flex: 0 0 auto; align-items: flex-end; border-bottom: 1px solid rgb(233, 233, 233);">
          <v-tab v-for="aceConfig in aceConfigs" v-bind:key="aceConfig.name">{{ aceConfig.name }}</v-tab>
        </v-tabs>
        
        <v-card-text class="full-flex" style="padding: 0;">
          <v-tabs-items v-model="activeTab">
            <v-tab-item v-for="aceConfig in aceConfigs" v-bind:key="aceConfig.name" class="tab-item">
              <div class="ace-container">
                <ace-editor :config="aceConfig" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="preview" class="full-flex">
      <vue-composer-preview :config="newConfig"></vue-composer-preview>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'vue-composer',
  data: () => ({
    aceConfigs: [],
    activeTab: null,
    newConfig: null,
    ready: false
  }),
  props: {
    config: Object,
    preview: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    config(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(newVal)
      }
    }
  },
  methods: {
    init(config) {
      this.destroy()

      if (!config || !this.ready) {
        return
      }

      config = this.newConfig = Object.assign({
        template: '',
        dataset: null,
        options: null
      }, config)

      console.log(config)
      
      this.aceConfigs = [
        {
          autoResize: true,
          beautify: false,
          name: 'template',
          onChange: value => {
            config.template = value
            this.triggerChange()
          },
          type: 'html',
          value: config.template
        },
        {
          autoResize: true,
          beautify: true,
          name: 'dataset',
          onChange: value => {
            try {
              config.dataset = JSON.parse(value)
              this.triggerChange()
            } catch(e) {
            }
          },
          type: 'json',
          value: JSON.stringify(config.dataset)
        },
        {
          autoResize: true,
          beautify: true,
          name: 'translations',
          onChange: value => {
            try {
              config.translations = JSON.parse(value)
              this.triggerChange()
            } catch(e) {
            }
          },
          type: 'json',
          value: JSON.stringify(config.translations)
        }
        // disabled because of eval() security vulnerability

        // keep code in case options should be used
        // {
        //   autoResize: true,
        //   beautify: false,
        //   name: 'options',
        //   onChange: value => {
        //     config.options = value
        //     this.triggerChange()
        //   },
        //   type: 'javascript',
        //   value: config.options
        // }
      ]    
    },
    destroy() {
      this.newConfig = null
      this.aceConfigs = []
    },
    triggerChange() {
      this.$emit('change', this.newConfig) 
    }
  },
  mounted () {
    this.ready = true

    this.init(this.config || {
      template: ''
    })
  },

  beforeDestroy() {
    this.destroy()
  }
}
</script>

<style lang="scss" scoped>
  .vue-composer {
    position: relative;
  }

  .preview-icon {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: 1000;
  }

  :deep {
    .ace-container {
      overflow: auto;
      display: flex;
      flex: 1 0 0;
      background: #f0f0f0;
      border-top: 1px solid rgb(233, 233, 233);
    }
  }

  .full-flex {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .tab-item {
    height: 300px;
  }

  $tabsSidebarWidth: 370px;

  // make tab items stretch to full height
  :deep {
    .data-entry-dialog  {
      overflow-y: visible;
    }

    .sv_body {
      padding: 0 !important;
      border: none !important;
    } 

    .v_window, .v-window__container, .v-window-item, .v-item-group {
      flex: 1 1 0;
      min-height: 0;
      display: flex;
    }
  }


  .tabs-sidebar {
    display: flex;
    flex: 0 0 auto;
    box-shadow: none;
    border-left: 1px solid rgb(233, 233, 233);
  }
</style>