import store from '@/store/index'

export default {
  async open(options) {
    let dialogObj

    let data = await new Promise((resolve, reject) => {
      dialogObj = {
        options: options,
        resolve: resolve,
        reject: reject
      }

      let dataEntryDialogs = store.state.dataEntryDialogs
      dataEntryDialogs.push(dialogObj)

      store.dispatch('setDataEntryDialogs', dataEntryDialogs)
    })

    await store.dispatch('setDataEntryDialogs', store.state.dataEntryDialogs.filter(dialog => dialog !== dialogObj))

    return data
  }
}