import api from '@/services/api';

const viewDataMap = new Map();


async function getViewData(sourceName, viewName, roleName) {
  let viewKey = sourceName + '.' + viewName; // key used for mapping
  let viewData = viewDataMap.get(viewKey);
  if (viewData === undefined) {
    let promise = api.call('getSourceView', {
      sourceName: sourceName,
      viewName: viewName,
      params: {
        roleName: roleName
      }
    });
    viewDataMap.set(viewKey, promise);
    let res = viewData = await promise;
    viewData = res == null ? null : res.data;
    viewDataMap.set(viewKey, viewData);
  } else if (viewData instanceof Promise) {
    // View data are currently retrieved by promise.
    let res = await viewData;
    viewData = res == null ? null : res.data;
  }
  return viewData;  
}


export { getViewData };