import store from '@/store/index'
import router from '@/router/index'

const SourceModel = function() {}

SourceModel.prototype = {}

Object.assign(SourceModel, {
  async routeTo(source, role, queryParams) {
    let roleName = role && role.name

    if ((store.state.source.name == source.name) && role && (router.currentRoute.name == 'analysis')) {
      queryParams = queryParams || {}

      let sourceName = store.state.source.name
      let viewName = store.state.view.name
      let analysisSidebar = store.state.analysisSidebar
      let analysisView = store.state.analysisView

      if (roleName) {
        // check if the current view has permissions for the current role
        if (role.viewNames.indexOf(viewName) == -1) {
          viewName = role.viewNames[0]
        }
      }

      router.push({
        name: 'analysisRedirect3',
        params: {
          sourceName: sourceName,
          viewName: viewName,
          roleName: roleName
        },
        query: Object.assign({
          analysisSidebar: analysisSidebar,
          analysisView: analysisView
        }, queryParams)
      })
    } else {
      router.push({ name: 'sourceRedirect', params: { sourceName: source.name, roleName: roleName }})
    }
  }
})

export default SourceModel