import store from '@/store/index'
import localStorageHelper from '@/services/localStorageHelper'
import error from '@/services/error'

const throwNoAccessError = (e, options) => {
  error.throw(
    e,
    Object.assign({
      type: 'warning',
      msgTranslation: 'errors.NO_ACCESS_RIGHTS',
      hideReload: true,
      hideClose: true,
      hideToStartPage: false
    }, options || {})
  )
}

const db = {
  async adjustBaseParams(sourceName, roleName, viewName) {
    let { oldSourceName, oldRoleName, oldViewName }  = { sourceName,  roleName, viewName }

    let sources = store.state.sources

    if (!sources) {
      sources = await store.dispatch('getSources')
    }

    if (sources.length === 0) {
      throwNoAccessError(null, {
        clientDebugData: {
          reason: 'no sources for user'
        }
      })
    }

    if (!sourceName) {
      let source = localStorageHelper.getSource()
      sourceName = source ? source.name : sources[0].name
    }

    let source = sources.find(source => source.name == sourceName)

    if (!source) {
      throwNoAccessError(null, {
        clientDebugData: {
          reason: 'source is not defined',
          sourceName: sourceName
        }
      })
    }

    let role
    let roles = source.roles

    roleName = roleName || localStorageHelper.getRoleName()

    if (roles) {
      if (roles.length == 0) {
        throwNoAccessError(null, {
          clientDebugData: {
            reason: 'source has no roles for user',
            sourceName: sourceName
          }
        })
      }

      if (!roleName) {
        role = roles[0]
        roleName = role.name
      }

      if (roleName) {
        role = roles.find(role => role.name == roleName)

        if (!role || (viewName && role.viewNames.indexOf(viewName) == -1)) {
          // try to adjust role 
          if (viewName) {
            role = roles.find(role => role.viewNames.indexOf(viewName) != -1)
          } else {
            role = roles[0]
          }
        }
        
        roleName = role ? role.name : null
      }
    } else {
      roleName = null
    }

    await store.dispatch('setRoleName', roleName)
    localStorageHelper.setRoleName(roleName)

    await store.dispatch('setSource', source)
    await store.dispatch('getSourceViews')

    localStorageHelper.setSourceName(source.name)

    let viewNames = role ? role.viewNames : source.viewNames

    if (viewNames.length == 0) {
      throwNoAccessError(null, {
        clientDebugData: {
          reason: 'user has no views in source',
          sourceName: source.name,
          roleName: roleName
        }
      })
    }

    viewName = viewName || localStorageHelper.getViewName(sourceName, roleName) || viewNames[0]

    viewName = (viewNames.indexOf(viewName) == -1 && oldSourceName) ? viewNames[0] : viewName

    if (!viewName) {
      throwNoAccessError(null, {
        clientDebugData: {
          reason: 'no view name',
          sourceName: source.name,
          viewName: viewName
        }
      })
    }

    await store.dispatch('getSourceView', { viewName: viewName })
    localStorageHelper.setViewName(sourceName, roleName, viewName)

    return {
      changed: oldRoleName != roleName || oldSourceName != sourceName || oldViewName != viewName,
      roleName,
      sourceName,
      viewName
    }
  },

  async getBase(sourceName, roleName, viewName) {
    let source = store.state.source
    let view = store.state.view
    let stateRoleName = store.state.roleName

    let stateSourceName = source ? source.name : null
    let stateViewName = view ? view.name : null

    if (stateSourceName == sourceName && stateRoleName == roleName && stateViewName == viewName) {
      return true
    }
    
    await db.adjustBaseParams(sourceName, roleName, viewName)

    await store.dispatch('getPageHtml')
  }
}

export default db