import store from '@/store/index'

export default {
  async open(title, message, options) {
    return await new Promise((resolve) => {
      let confirmDialogObj = {
        title: title,
        message: message,
        options: options,
        resolve: returnVal => {
          store.dispatch('setConfirmDialogs', store.state.confirmDialogs.filter(confirmDialog => confirmDialog !== confirmDialogObj))

          resolve(returnVal)
        }
      }

      store.state.confirmDialogs.push(confirmDialogObj)

      store.dispatch('setConfirmDialogs', store.state.confirmDialogs)
    })
  }
}