<template>
  <iframe class="i-frame full-flex" ref="i-frame"></iframe>
</template>

<script>
export default {
  name: 'IFrame',
  data: () => ({
    initContent: null
  }),
  props: {
    content: Object
  },
  watch: {
    content() {
      this.init()
    }
  },
  methods: {
    init() {
      if (this.content == this.initContent) {
        return
      }

      this.initContent = this.content

      this.$el.srcdoc = this.content ? this.content.innerHTML : ''
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.i-frame {
  border: 1px solid #eee;
}

.full-flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
</style>