<template>
  <div>
    <v-dialog
      v-model="value"
      width="80%"
      :scrollable="true"
    >
      <v-card height="80vh">
        <v-card-title class="text-h6 white--text primary">{{ $t('texts.email.send_emails') }}</v-card-title>
        <v-card-text style="padding: 20px 32px;">
          <div style="display: grid;  grid-template-columns: minmax(auto, 40%) auto; grid-template-rows:  min-content 1fr; gap: 32px 48px; width: 100%; height: 100%; ">
            <!-- email templates -->
            <div style="flex: 1 1 auto;">
              <div class="text-subtitle-1">
                {{ $t('texts.email.email_template') }}
              </div>
              <v-select
                v-model="selectedEmailTemplate"
                :items="emailTemplates"
                item-text="label"
                :return-object="true"
              />
            </div>
            <!-- description -->
            <div>
              <div class="text-subtitle-1">
                {{ $t('texts.email.description') }}
              </div>
              <div style="white-space: pre-line; padding-top: 16px; max-height: 84px; overflow-y: auto;">
                {{emailDescription === '' ? '-' : emailDescription}}
              </div>
            </div>
            <!-- recipients -->
            <div class="email-recipients" :style="{visibility: emailTemplate == null ? 'hidden' : 'visible'}">
              <div class="text-subtitle-1">{{ $t('texts.email.recipients_ready', { count: readyRecipientsCount, length: emailRecipients.length }) }}</div>
              <div class="recipients-table-container">
                <table class="recipients-table">
                  <tr>
                    <th class="id-column-header">ID</th>
                    <th class="address-column-header">{{ $t('texts.email.email_address') }}</th>
                    <th class="status-column-header">{{ $t('texts.email.status') }}</th>
                  </tr>
                  <tr class="recipient" v-for="(recipient, index) of emailRecipients" :key="index" :class="{selected: (selRecipientIndex === index) && recipient.isReady, ready: recipient.isReady}" @click="selectTableRow(index)">
                    <td class="id-column-cell">{{index+1}}</td>
                    <td>
                      <div class="address-column-content">
                        <div style="display: contents;">
                          <div v-if="recipient.toFormatted">{{ $t('texts.email.to') }}:</div>
                          <div style="white-space: pre-wrap;">{{recipient.toFormatted}}</div>
                        </div>
                        <div v-if="recipient.ccFormatted" style="display: contents;">
                          <div>CC:</div>
                          <div style="white-space: pre-wrap;">{{recipient.ccFormatted}}</div>
                        </div>
                        <div v-if="recipient.bccFormatted" style="display: contents;">
                          <div>BCC:</div>
                          <div style="white-space: pre-wrap;">{{recipient.bccFormatted}}</div>
                        </div>
                      </div>
                    </td>
                    <td class="status-column-cell">
                      <div style="display: flex;">
                        <v-icon>mdi-{{recipient.isReady ? "check" : "close"}}</v-icon>
                        <div style="white-space: pre;  margin-left: 8px;">{{recipient.status}}</div>
                      </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            <!-- preview -->
            <div class="email-preview" :style="{visibility: emailTemplate == null ? 'hidden' : 'visible'}">
                <div style="display: flex; align-items: center;  justify-content: space-between;">
                    <div class="text-subtitle-1 preview-heading">
                      {{ $t('texts.email.preview') }}
                    </div>
                </div>  
                <div v-if="selEmailDataIndex >= 0" style="display: flex; flex-direction: column; flex: 1 1 0;">
                  <div class="text-caption preview-subheading">{{ $t('texts.email.subject') }}</div>
                  <div class="font-weight-regular subject-preview" style="overflow-x: auto; min-width: 100px;"><span>{{emailSubject}}</span></div>
                  <div v-if="emailAttachments && emailAttachments.length > 0" style="margin: 12px 0 8px;">
                    <div class="text-caption preview-subheading">
                      {{ $t('texts.email.attachments') }}
                    </div>
                    <div style="display: flex; overflow: hidden;">
                      <div v-for="attachment in emailAttachments" style="background: #f0f0f0; border-radius: 10px; padding: 4px 8px; margin: 0 4px;">
                        {{attachment.filename.split("/").at(-1)}}
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center; margin-top: 12px;">
                    <div class="text-caption preview-subheading" style="flex: 1 1 auto;">{{ $t('texts.email.content') }}</div>
                    <v-btn text class="preview-content-button" :class="{selPreviewContentButton: previewContentType==='html'}" @click="previewContentType = 'html'">HTML</v-btn>
                    <v-btn text class="preview-content-button" :class="{selPreviewContentButton: previewContentType==='text'}"  @click="previewContentType = 'text'">{{ $t('texts.email.text') }}</v-btn>
                  </div>
                  <div style="flex: 1 1 0; overflow-x: auto; overflow-y: scroll; border: 1px solid #f0f0f0; display: flex; justify-content: stretch;">
                    <div v-if="previewContentType==='text'" class="font-weight-regular content-preview text-preview">{{emailText}}</div>
                    <div v-else class="font-weight-regular content-preview">
                      <iframe :srcdoc="emailHtml" style="width: 100%; border: 1px solid #eee; height: 100%;"></iframe>
                      <!-- <div v-html="emailHtml"></div> -->
                  </div>
                </div>
              </div>
              <div v-else class="no-preview">
                {{ $t('texts.email.no_preview') }}
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="isTestShowing=true" :disabled="emailTemplate == null || readyRecipientsCount === 0">{{ 'Send Test Email' }}</v-btn>
        <v-spacer />
        <v-btn text @click="close">{{ $t('actions.cancel') }}</v-btn>
        <v-btn text color="primary" @click="showSendDialog" :disabled="emailTemplate == null || readyRecipientsCount === 0">{{ $t('actions.send') }}</v-btn>
      </v-card-actions>

      <!-- test email dialog -->
        <v-dialog persistent v-model="isTestShowing" width="500">
          <v-card>
            <v-card-title class="text-h6 white--text primary">
              {{ $t('texts.email.send_test_email') }}
            </v-card-title>
              <v-card-text><v-text-field
                  :label="$t('texts.email.recipient')"
                  v-model="testTo"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="isTestShowing=false">
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="isTestShowing=false; sendEmails(true)" :disabled="!emailRegexTest(testTo)">
                {{ $t('actions.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- send confirmation dialog -->
        <v-dialog persistent v-model="isWarningShowing" width="500">
          <v-card>
            <template v-if="!sending && sentCount === 0">
              <v-card-title class="text-h6 white--text primary">
                {{ $t('texts.email.ready_to_send') }}
              </v-card-title>
              <v-card-text>
                <div class="py-4">
                  {{ $t('texts.email.email_will_be_sent___', {count: readyRecipientsCount }) }}
                </div>
              </v-card-text>
            </template>
            <template v-if="sentFinished">
              <v-card-title class="text-h6 white--text primary">
                {{ $t('texts.email.finished') }}
              </v-card-title>
              <v-card-text class="text-center pa-10">
                <v-icon large>mdi-check</v-icon>
              </v-card-text>
            </template>
            <template v-if="sending">
              <v-card-title class="text-h6 white--text primary">
                {{ $t('texts.email.sending') }}...
              </v-card-title>
              <v-card-text>
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <div style="flex: 1; padding: 25px 0;">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="70"
                      :width="7"
                    ></v-progress-circular>
                  </div>
                  <div style="flex: 1;">
                    {{sentCount}}/{{emailDatas.length}}
                  </div>
                </div>
              </v-card-text>
            </template>  
            <v-card-actions v-if="!sending">
              <v-spacer></v-spacer>
              <v-btn text v-if="sentFinished" @click="close">{{ $t('actions.close') }}</v-btn>
              <v-btn text v-if="!sending && !sentFinished" @click="isWarningShowing=false">{{ $t('actions.cancel') }}</v-btn>
              <v-btn text color="primary" v-if="sentCount === 0" @click="sendEmails(false)">{{ $t('actions.ok') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/services/api';
import templateParser from '@/shared_services/templateParser';
import evalExpression from '@/services/boolExEval';
import templateFunctions from '@/services/templateFunctions';
import confirmDialog from '@/services/confirmDialog';

const STATUS_READY_TO_SEND = "readyToSend";
const STATUS_CONSTRAINT_NOT_FULFILLED = "constraintNotFulfilled";
const STATUS_MISSING_ADDRESS = "addressMissing";
const STATUS_LANGUAGE_MISSING = "languageMissing";
const { parseTemplate } = templateParser;


function concatEmailStrings(str1, str2) {
  // Concat strings
  let resultStr = (str1 == null ? '' : str1.trim()) + ';' + (str2 == null ? '' : str2.trim());
  // Remove any extra semicolons
  resultStr = resultStr.replaceAll(/^;|(?<=;);+|;$/g, '');
  return resultStr;
}


/**
 * Removes all duplicate entries in the address arrays.
 * Also, removes all addresses in the to array from the cc and bcc arrays, and all addresses in the cc array from
 * the bcc array.
 *
 * @param {Object} toArray
 * @param {Object} ccArray
 * @param {Object} bccArray
 */
function removeDuplicateAddresses(toArray, ccArray, bccArray) {
  // Split address strings at semicolon, comma and whitespace and create a set from tokens.
  // This will remove all duplicates within each field (to, cc, and bcc).
  // The filter will remove all empty strings.
  let toSet = new Set(toArray)
  let ccSet = new Set(ccArray)
  let bccSet = new Set(bccArray)

  // Remove all addresses in to from cc and bcc
  toArray.splice(0, toArray.length, ...Array.from(toSet))
  ccArray.splice(0, ccArray.length, ...Array.from(ccSet).filter(v => !toSet.has(v)))
  bccArray.splice(0, bccArray.length, ...Array.from(bccSet).filter(v => !(toSet.has(v) || ccSet.has(v))))
}


export default {
  name: 'emailDialog',
  props: {
    selDatasets: Array,
    datasetsElement: Object,
    value: Boolean // v-model value
  },
  data: function () {
    return {
      emailRecipients: [],
      selectedEmailTemplate: null,
      selRecipientIndex: -1,
      isWarningShowing: false,
      previewContentType: 'html',
      sentCount: 0,
      sentFinished: false,
      sending: false,
      isTestShowing: false,
      testTo: '', // recipient for test emails
      emailSubject: '',
      emailText: '',
      emailHtml: '',
      emailAttachments: []
    }
  },
  computed: {
    emailTemplates() {
      return this.$store.state.view.email_templates;
    },
    emailTemplate() {
      let selectedEmailTemplate = this.selectedEmailTemplate;
      if (selectedEmailTemplate) {
        return Object.assign({}, selectedEmailTemplate);
      } else {
        return null;
      }
    },
    emailDescription() {
      let description = (this.emailTemplate && this.emailTemplate.description) || '';   
      // Allows html style line breaks. Replace these with newline chars.   
      description = description.replaceAll('<br>', '\n');
      return description;
    },
    readyRecipientsCount() {
      let readyCount = 0;
      for (let recipient of this.emailRecipients) {
        if (recipient.status === STATUS_READY_TO_SEND) {
          readyCount++;
        }
      }
      return readyCount;
    },
    emailDatas() {
      let selDatasets = this.selDatasets;
      let recipients = this.emailRecipients;
      if (recipients == null || recipients.length === 0) {
        return [];
      }
      let emailDatas = [];
      for (let i = 0; i < selDatasets.length; i++) {
        let row = selDatasets[i];
        let recipient = recipients[i];
        if (recipient.status === STATUS_READY_TO_SEND) {
          emailDatas.push({
            _id: row._id, // MongoDB document id (string starting with 'o')
            to: recipient.to,
            cc: recipient.cc,
            bcc: recipient.bcc
          });
        }
      }
      return emailDatas;
    },
    selEmailDataIndex() {
      let selEmailDataIndex = -1;
      if (this.emailTemplate) { // needed to be reactive to template changes
        let recipients = this.emailRecipients;
        for (let i = 0; i <= this.selRecipientIndex; i++) {
          if (recipients[i].status === STATUS_READY_TO_SEND) {
            selEmailDataIndex++;
          }
        }
      }
      return selEmailDataIndex;
    },
    languages() {
      let languages = [];
      let emailTemplate = this.emailTemplate;
      if (emailTemplate != null) {
        languages = Object.keys(emailTemplate.subject);
      }  
      return languages;    
    }
  },
  watch: {
    value(b) {
      if (b) {
        this.initDialog();
      } else {
        this.selectedEmailTemplate = null;
      }
    },
    emailRecipients() {
      this.emailText = '';
      this.emailHtml = '';
      this.selectFirstReadyRecipient();
    },
    emailTemplate() {
      this.emailText = '';
      this.emailHtml = '';
      this.updateEmailRecipients().then(this.updatePreviewData);
    },
    selRecipientIndex() {
      //this.emailText = '';
      //this.emailHtml = '';
      this.updatePreviewData();
    },
    selDatasets() {
      // Select first ready row
      let firstReadyIndex = -1;
      if (this.emailRecipients != null) {
        for (let i = 0; i < this.emailRecipients.length; i++) {
          if (this.emailRecipients[i].isReady) {
            firstReadyIndex = i;
            break;
          }
        }      
      }
      this.selRecipientIndex = firstReadyIndex;
    }
  },  
  methods: {
    async updateEmailRecipients() {
      let recipients = [];
      let selDatasets = this.selDatasets;
      if (selDatasets == null || selDatasets.length === 0) {
        this.emailRecipients = recipients;
        return;
      }
      let emailRegex =  /\S+@\S+\.\S+/;
      let emailTemplate = this.emailTemplate;
      if (emailTemplate != null) {
        let constraints = emailTemplate.constraints;
        let constraint2 = emailTemplate.constraint2;
        let languageSelector = emailTemplate.language_selector || '';
        let distributionLists = await this.getDistributionLists(emailTemplate, selDatasets.map(row => ({_id: row._id})))

        for (let i = 0; i < selDatasets.length; i++) {
          let row = selDatasets[i];
          let id = row._id;

          let toTemplate = emailTemplate.to || '';
          let ccTemplate = emailTemplate.cc || '';
          let bccTemplate = emailTemplate.bcc || '';
          if (distributionLists && distributionLists.length === selDatasets.length) {
            let distrList = distributionLists[i];
            toTemplate = concatEmailStrings(toTemplate, distrList.emailListTo);
            ccTemplate = concatEmailStrings(ccTemplate, distrList.emailListCC);
            bccTemplate = concatEmailStrings(bccTemplate, distrList.emailListBCC);
          }
          let parseCallback = (tag, args) => this.replaceTag(tag, args, row);
          let toStr = parseTemplate(toTemplate, parseCallback);
          let ccStr = parseTemplate(ccTemplate, parseCallback);
          let bccStr = parseTemplate(bccTemplate, parseCallback);

          // Remove duplicate emails
          // Convert string to array, trim addresses, and remove empty entries
          let toArray = toStr.split(';').map(e => e.trim()).filter(e => e);
          let ccArray = ccStr.split(';').map(e => e.trim()).filter(e => e);
          let bccArray = bccStr.split(';').map(e => e.trim()).filter(e => e);

          removeDuplicateAddresses(toArray, ccArray, bccArray);

          toStr = toArray.join(';');
          ccStr = ccArray.join(';');
          bccStr = bccArray.join(';');

          let toFormatted = toStr.replaceAll(';', '\n');
          let ccFormatted = ccStr.replaceAll(';', '\n');
          let bccFormatted = bccStr.replaceAll(';', '\n');

          let language = parseTemplate(languageSelector, parseCallback);
          let status;
          if (!emailRegex.test(toStr)) {
            status = STATUS_MISSING_ADDRESS;
          }
          if (constraints) {
            for (let constraint of constraints) {
              let expression = constraint.expression || '';
              if (!this.evaluateConstraint2(expression, row)) {
                let label = constraint.label || '';
                status = (status == null ? '' :  status + '\n') + label;
              }
            }
          } else {
            if (status == null && !this.evaluateConstraint2(constraint2, row)) {
              status = STATUS_CONSTRAINT_NOT_FULFILLED;
            }
          }
          if (status == null) {
            if (!this.languages.includes(language)) {
              status = STATUS_LANGUAGE_MISSING;
            } else {
              status = STATUS_READY_TO_SEND;
            }
          }

          recipients.push({
            id: id,
            to: toStr,
            cc: ccStr === '' ? null : ccStr,
            bcc: bccStr === '' ? null : bccStr,
            toFormatted: toFormatted,
            ccFormatted: ccFormatted,
            bccFormatted: bccFormatted,
            status: status,
            language: language,
            isReady: status === STATUS_READY_TO_SEND
          });
        }
      }
      this.emailRecipients = recipients;
    },
    updatePreviewData() {
      let selEmailDataIndex = this.selEmailDataIndex;
      let emailTemplate = this.emailTemplate;
      if (selEmailDataIndex < 0 || emailTemplate == null) {
        this.emailText = '';
        this.emailHtml = '';
      } else {
       // let emailData = this.emailDatas[selEmailDataIndex];
      //  this.emailText = (emailData && emailData.text) || '';
       // this.emailHtml = (emailData && emailData.html) || '';
      }
    },
    close() {
      this.$emit(`input`, false) // will set the input value (v-model handling)
    },
    initDialog() {
      if (this.emailTemplates != null && this.emailTemplates.length > 0) {
        this.selectedEmailTemplate = this.emailTemplates[0];
      }
      this.isWarningShowing = false;
      this.sentCount = 0;
      this.sentFinished = false;
      this.sending = false;
    },
    async getDistributionLists(emailTemplate, resultIds) {
      let distributionLists = null;
      if (emailTemplate.distribution_list === true) {
        let emailTemplateName = emailTemplate.name;
        await api.call(
            'getEmailDistributionLists',
            {
              data: {
                emailTemplate: emailTemplateName,
                resultIds: resultIds
              }
            },
            (res) => {
              console.log("Callback distr list", res)
              distributionLists = res;
            },
            async () => {

            }
        )
      }
      return distributionLists;
    },
    showSendDialog() {
      this.sentCount = 0;
      this.sending = false;
      this.sentFinished = false;
      this.isWarningShowing = true;
    },
    async selectTableRow(index) {
      await api.call('renderEmail',
          // data
          {
            emailTemplateName: this.emailTemplate.name,
            id: this.emailRecipients[index].id
          },
          // reply
          async data => {
            this.emailSubject = data.subject;
            this.emailHtml = data.html;
            this.emailText = data.text;
            this.emailAttachments = data.attachments;
          },
          null)

      if (this.emailRecipients[index].isReady) {
        this.selRecipientIndex = index;
      }
    },
    selectFirstReadyRecipient() {
      let recipients = this.emailRecipients;
      for (let i = 0; i < recipients.length; i++) {
         if (recipients[i].status === STATUS_READY_TO_SEND) {
          this.selectTableRow(i);
          break;
        }
      }
    },
    evaluateConstraint2(constraint, row) {
      //constraint = "$row.status_info.new_status == 'P - Confirmed' and $row.casedata.project_work_description nin [null, ''] and $row.respondent.salutation nin [null, '']"
      if (constraint == null || constraint === '') {
        return true;
      }
      return evalExpression(constraint, {row: row});
    },
    emailRegexTest(emailString) {
      return /\S+@\S+\.\S+/.test(emailString)
    },
    async refreshDatasets() {
      await this.datasetsElement.refreshDatasets()
    },
    replaceTag(tag, args, row) {
      if (args == null) {
        if (row == null) {
          return '';
        } else {
          // tag is a row field
          let tagTokens = tag.replaceAll('-', '.').split('.');
          let value = row;
          if (tagTokens[0] === 'row') {
            tagTokens.shift();
          }
          while (value != null && tagTokens.length) {
            value = value[tagTokens.shift()];
          }
          return value || '';
        }
      } else {
        let templateFunction = templateFunctions[tag]
        if (templateFunction) {
          // tag is a function
          return templateFunction(args, row)
        }
      }
    },
    async sendEmails(isTestEmail) {
      try {
        let emailDatas = this.emailDatas;

        if (isTestEmail) {
          // Use only first emailData entry
          let selEmailDataIndex = this.selEmailDataIndex;
          emailDatas = emailDatas[selEmailDataIndex] ? [Object.assign({}, emailDatas[selEmailDataIndex])] : [];
          emailDatas[0].isTestEmail = true;
          emailDatas[0].to = this.testTo;
          emailDatas[0].cc = '';
          emailDatas[0].bcc = '';
        }
        let accountName = this.emailTemplate.email_account;

        this.sending = true
        this.sentFinished = false

        let batchSize = Math.ceil(emailDatas.length / Math.ceil(emailDatas.length / 20)) // Maximum 20 email per batch, but distribute evenly

        for (let i = 0, isError=false; i < emailDatas.length && !isError; i += batchSize) {
          this.sentCount = Math.min(emailDatas.length, i + batchSize);
          await api.call(
            'sendEmails',
            {
              data: {
                isTestEmail: isTestEmail,
                accountName: accountName,
                emailDatas: emailDatas.slice(i, i + batchSize),
                emailTemplateName: this.emailTemplate.name
              }        
            },
            null,
            () => {
              let msg = "There was an error when sending email " + (i + 1)
              confirmDialog.open("Error", msg, {hideCancel: true})
              isError = true;
            }
          )
        }
        this.sentFinished = true;
      } catch(err) {
        throw err
      } finally {
        if (!isTestEmail) {
          this.refreshDatasets()
        }
        if (!this.sentFinished) {
          this.sentFinished = true;
          this.isWarningShowing = false
        }
        this.sending = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.email-recipients {
  display: flex;
  flex-direction: column;
}

.recipient {
  padding: 2px 16px;
}

.email-template {
  margin-top: 16px;
  margin-bottom: 16px;
}

.email-preview {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.language-field {
  margin: 0 0 0 16px;
  max-width: 100px;
}

.subject-preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 16px;
  overflow: hidden;
}

.content-preview {
  flex: 1 1 auto;
  margin: 16px;
}

.text-preview {
  white-space: pre-wrap;
}

.no-preview {
  margin-top: 16px;
  opacity: 0.7;
}

.recipients-table-container {
  display: block;
  height: 192px;
  overflow: auto;
  margin-top: 16px;
  flex: 1 1 auto;
  overflow-y: scroll;
}

.recipients-table {
  padding: 0;
  border: none;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.recipients-table th {
  position: sticky;
  top: 0;
  padding: 6px 12px;
  border: none;
  border-bottom: 1px solid #e0e0e0; ;
  cursor: default;
  z-index: 5;
  background: #ffffff;
}

.recipients-table tr {
  padding: 6px 0;
  border: none;
}

.recipients-table td {
  border-bottom: none;
  padding: 4px 12px;
  border-bottom: 1px solid #e0e0e0;
  cursor: default;
  opacity: 0.7;
}

.recipients-table .ready td {
  cursor: pointer;
  opacity: 1;
}

.recipients-table .selected td {
  background:#b7e4ff;
}

.id-column-header {
  text-align: center;
}

.address-column-header {
  text-align: left;
}

.status-column-header {
  text-align: left;
}

.id-column-cell {
  text-align: center;
  vertical-align: top;
}

.address-column-content {
  text-align: left;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px;
}

.status-column-cell {
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
}

.preview-heading {
  margin-bottom: 12px;
}

.preview-subheading {
  margin-bottom: 12px;
}

.preview-content-button {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  border-bottom: 3px solid transparent;
  opacity: 0.7;
}

.preview-content-button::before {
  background: none !important;
}

.preview-content-button:hover {
  opacity: 1;
  border-bottom: 3px solid #c0c0c0;
}

.preview-content-button.selPreviewContentButton {
  opacity: 1;
  border-bottom: 3px solid red;
}
</style>