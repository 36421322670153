import datepicker from 'js-datepicker'
import dateFormat from 'dateformat'
import { getLocale } from '@/i18n'

export default {
  getVueMaterialDatePicker() {
    const lang = {
      en: {
        overlayButton: 'Apply',
        overlayPlaceholder: 'ENTER YEAR'
      },
      de: {
        customMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        overlayButton: 'Anwenden',
        overlayPlaceholder: 'JAHR EINTRAGEN',
        startDay: 1
      }
    }

    function Datepicker() {}

    // gets called once before the renderer is used
    Datepicker.prototype.init = function(params) {
      // create the cell
      this.eInput = document.createElement('input')
      this.eInput.value = params.value
      this.eInput.id = 'material-datepicker'
      this.eInput.classList.add('ag-input')
      this.eInput.style.height = '100%'
    };

    // gets called once when grid ready to insert the element
    Datepicker.prototype.getGui = function() {
      return this.eInput
    }

    // focus and select can be done after the gui is attached
    Datepicker.prototype.afterGuiAttached = function() {
      let options = {
        formatter: (input, date, instance) => {
          input.value = dateFormat(date, "yyyy-mm-dd")
        },
        dateSelected: new Date(this.eInput.value)
      }
      
      Object.assign(options, lang[getLocale()] || {})
      
      this.datepicker = datepicker(this.eInput, options)

      this.eInput.focus()
      this.eInput.select()
    }

    Datepicker.prototype.getValue = function() {
      return this.eInput.value
    }

    return Datepicker
  }
}