<template>
  <div class="analysis-settings-bookmark" v-bind:class="{ selected: $store.state.linkedBookmarkId === bookmark.id }" @click="selectBookmark">
    <md-dialog :md-active.sync="showBookmarkUrlDialog">
      <md-dialog-title>{{ $t('texts.BOOKMARK_LINK') }}</md-dialog-title>
      <md-dialog-content style="overflow-y:hidden;">
        <div style="display: flex; align-items: center;">
          <div style="flex: 1 0 0; background: #f5f5f5; border: 1px solid #ddd; padding: 0 10px;">
            <div style="font-size: 16px; visibiliy: hidden; overflow: hidden; height: 0;">
              <!-- not visible: only used to stretch dialog -->
              {{ bookmarkUrl }}
            </div>
            <md-field>  
              <md-input @click="clickBookmarkUrl" v-model="bookmarkUrl" readonly></md-input>
            </md-field>
          </div>
          <div style="flex: 0 0 0;">
            <md-button @click="copyUrlToClipboard()" class="md-icon-button"><md-icon>file_copy</md-icon><md-tooltip>{{ $t('texts.COPY_LINK_TO_CLIPBOARD') }}</md-tooltip></md-button>
          </div>
        </div>
      </md-dialog-content>
      
      <md-dialog-actions>
        <md-button @click="showBookmarkUrlDialog = false">{{ $t('actions.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <v-tooltip bottom open-delay="1000" :offset-overflow="true">
      <template v-slot:activator="{ on, attrs }">
        <div class="text" v-bind="attrs" v-on="on">{{ bookmark.name }}</div>
      </template>
      <span>{{ bookmark.name }}</span>
    </v-tooltip>

    <div>
      <v-tooltip bottom open-delay="1000" :offset-overflow="true" v-if="bookmark.hasFilter">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon" v-bind="attrs" v-on="on" x-small color="#BFBD13">mdi-filter-outline</v-icon>
        </template>
        <span>{{$t('tooltips.FILTER')}}</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="1000" :offset-overflow="true" v-if="bookmark.analysisView === 'datasets'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon" v-bind="attrs" v-on="on" x-small color="#0ABFA4">mdi-format-list-numbered</v-icon>
        </template>
        <span>{{$t('tooltips.VIEW_DATASETS')}}</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="1000" :offset-overflow="true" v-if="bookmark.analysisView === 'tabulation'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon" v-bind="attrs" v-on="on" x-small color="#F25818">mdi-table</v-icon>
        </template>
        <span>{{$t('tooltips.VIEW_TABULATION')}}</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="1000" :offset-overflow="true" v-if="bookmark.analysisView === 'chart'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon" v-bind="attrs" v-on="on" x-small color="#49BF0A">mdi-chart-line</v-icon>
        </template>
        <span>{{$t('tooltips.VIEW_CHARTS')}}</span>
      </v-tooltip>
    </div>
    
    <div v-if="editRights">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="grey">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="editBookmark">
            <v-list-item-content>{{ $t('actions.edit') }}</v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="deleteBookmark">
            <v-list-item-content>{{ $t('actions.delete') }}</v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="openBookmarkUrlDialog">
            <v-list-item-content>{{ $t('actions.bookmark_link') }}</v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>        
  </div>
</template>

<script>
export default {
  name: 'AnalysisSettingsBookmark',
  props: [
    'bookmark',
    'editRights'
  ],
  data() {
    return {
      showBookmarkUrlDialog: false,
      bookmarkUrl: null
    }
  },
  methods: {
    editBookmark() {
      this.$emit('edit')
    },
    selectBookmark() {
      this.$store.dispatch('setLinkedBookmarkId', this.bookmark.id)

      this.$emit('select')
    },
    deleteBookmark() {
      this.$emit('delete')
    },
    clickBookmarkUrl(event) {
      event.target.select()
    },
    openBookmarkUrlDialog() {
      let protocol = location.protocol
      let port = location.port
      let host = window.location.hostname
      this.bookmarkUrl = protocol + '//' + host + (port && port !== 80 && port !== 443 ? ':' + port : '') + '/bookmark/' + this.$store.state.source.name + '/' + this.bookmark.id
      this.showBookmarkUrlDialog = true
    },
    copyUrlToClipboard() {
      navigator.clipboard.writeText(this.bookmarkUrl).then(() => {}, (err) => {
        console.error('Could not copy url: ', err)
      })
    }
  },
  async beforeMount() {
  }
}
</script>

<style lang="scss" scoped>
  .analysis-settings-bookmark {
    display: flex;
    align-items: center;
    padding: 2px 10px 2px 40px;
    cursor: pointer;
    min-height: 40px;

    .text {
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon {
      padding: 0 0 0 3px;
    }
  }

  .analysis-settings-bookmark:hover {
    background: rgb(246, 246, 246);
  }

  .selected, .analysis-settings-bookmark.selected:hover {
    background: #eee;
  }


</style>