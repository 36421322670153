<template>
  <v-card>
    <v-card-title class="text-h6 white--text primary">
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="name"
          label="Name"
          :disabled="!!_id"
          :rules="rules.name"
        />
        <v-text-field
          v-model="label"
          label="Label"
          :rules="rules.label"
        />
        <v-text-field
          v-model="group"
          label="Group"
        />
        <v-text-field
          v-model="description"
          label="Description"
        />
        <v-select
          v-model="type"
          :items="types"
          :disabled="!!_id"
          label="Type"
          :rules="rules.type"
        />
        <v-select
          v-if="type && subtypes[type]"
          v-model="subtype"
          :items="subtypes[type]"
          :disabled="!!_id"
          label="Subtype"
        />
        <v-select
          v-if="scale"
          v-model="scale"
          :items="scales"
          label="Scale"
        />
      </v-form>
    </v-card-text>

    <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">{{$t('actions.cancel')}}</v-btn>
      <v-btn color="primary" text @click="save">{{$t('actions.save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'

import VariableModel from '@/models/variable'

export default {
  name: 'VariableForm',
  props: {
    surveyType: String,
    variable: Object
  },
  data: () => ({
    _id: null,
    defaultSubtypes: null,
    description: null,
    group: null,
    label: null,
    name: null,
    rules: {
      name: [
        v => !!v || 'Name is required',
        v => (!!v && v.length >= 3) || 'Name must have at least 3 characters'
      ],
      label: [
        v => !!v || 'Label is required',
        v => (!!v && v.length >= 3) || 'Name must have at least 3 characters'
      ],
      type: [
        v => !!v || 'Type is required'
      ]
    },
    scale: null,
    scales: [
      'nominal',
      'ordinal',
      'ratio'
    ],
    subtype: null,
    subtypes: null,
    type: null,
    types: null,
    valid: false
  }),
  computed: { 
    title() {
      return this._id ? 'Update Variable' : 'Insert Variable'
    }    
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    getDefaultSubtype(type) {
      return (this.subtypes[type] && this.subtypes[type].length > 0) ? this.subtypes[type][0] : null
    },
    save() {
      this.validate()

      if (this.valid) {
        let vals = {
          _id: this._id,
          name: this.name,
          label: this.label,
          group: this.group,
          description: this.description,
          type: this.type
        }

        if (this.scale) {
          vals.scale = this.scale
        }

        if (this.subtype) {
          vals.subtype = this.subtype
        }

        this.$emit('save', vals)
      }
    },
    setVariable() {
      /*for (let typeName in VariableModel.WOENENN_TYPES) {
        let typeDef = VariableModel.WOENENN_TYPES[typeName]
        let subtypes = typeDef.subtypes

        if (subtypes) {
          let subtypeArr = []

          for (let subtypeName in subtypes) {
            subtypeArr.push(subtypeName)
          }

          this.subtypes[typeName] = subtypeArr
        }
      }*/

      this._id = (this.variable && this.variable._id) || null
      this.name = (this.variable && this.variable.name) || null
      this.label = (this.variable && this.variable.label) || null
      this.group = (this.variable && this.variable.group) || null
      this.description = (this.variable && this.variable.description) || null
      this.type = (this.variable && this.variable.type) || null
      this.subtype = (this.variable && this.variable.subtype) || null
      this.scale = (this.variable && this.variable.scale) || null
      
      let surveyTypeDef = VariableModel.SURVEY_TYPES[this.surveyType]//.map(typeName => { return { name: typeName } })

      this.types = []
      this.subtypes = {}

      if (surveyTypeDef.subtypes) {
        for (let surveyType in surveyTypeDef.subtypes) {
          let surveySubtypeDef = surveyTypeDef.subtypes[surveyType]

          let type = surveySubtypeDef.woenennType
          let subtype = surveySubtypeDef.woenennSubtype
          this.types.push(type)

          if (subtype) {
            let subtypes = this.subtypes[type] = this.subtypes[type] || []

            subtypes.push(subtype)
          }
        }

        this.type = this.type || this.types[0]
        this.subtype = this.surveyType || (this.subtypes[type] ? this.subtypes[type][0] : null)
      } else {
        if (this.type && this.type != surveyTypeDef.woenennType) {
          throw new Error(`WOENENN type "${this.type}" not possible for survey type "${this.surveyType}"`)
        }

        this.type = surveyTypeDef.woenennType
        this.types = [surveyTypeDef.woenennType]

        if (surveyTypeDef.woenennSubtype) {
          if (this.subtype && this.subtype != surveyTypeDef.woenennSubtype) {
            throw new Error(`WOENENN subtype "${this.subtype}" of type "${this.type}" not possible for survey type "${this.surveyType}"`)
          }

          this.subtype = surveyTypeDef.woenennSubtype
          this.subtypes[surveyTypeDef.woenennType] = [surveyTypeDef.woenennSubtype]
        }
      }
    },
    validate () {
      this.$refs.form.validate()
    }
  },
  watch: {
    type(newType, oldType) {
      if (oldType) {
        this.subtype = this.getDefaultSubtype(newType)
      }

      this.scale = (this.type == 'categorical') ? (this.scale || this.scales[0]) : null
    },
    variable() {
      this.setVariable()
    }
  },
  async beforeMount() {
    this.setVariable()
  }
}
</script>

<style scoped lang="scss">
</style>